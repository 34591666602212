import { AAMCIDCSVData } from 'enums';
import { clone, colors } from './schedule';

export const assignColorToCandidates = (candidates) => {
  const newCandidates = clone(candidates);
  let colorKey = 0; // Math.floor(Math.random() * colors.length);
  Object.keys(newCandidates).forEach((key, i) => {
    if (colorKey >= colors.length) {
      colorKey = 0;
    }
    newCandidates[key].color = colors[colorKey];
    colorKey++;
  });

  return newCandidates;
};

export const separateDataTypesByAvailability = (dataTypes = {}) => {
  const enabled = [],
    disabled = [];

  Object.values(dataTypes).forEach((dataType) => {
    if (dataType.Enabled) {
      enabled.push(dataType);
    } else {
      disabled.push(dataType);
    }
  });

  enabled.sort((a, b) => a.SortOrder - b.SortOrder);
  disabled.sort((a, b) => {
    const textA = a.Alias ? a.Alias.toUpperCase() : '';
    const textB = b.Alias ? b.Alias.toUpperCase() : '';
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return { enabled, disabled };
};

/***
 * arranges Sort orders.
 * @param {string} listOrigin - 'enabled'/'disabled'
 */
export const prepareDataTypesForSortUpdate = (dataTypes, dataTypeToUpdate) => {
  const newDataTypes = clone(dataTypes);
  const { enabled, disabled } = newDataTypes;
  const { listOrigin } = Object.values(dataTypeToUpdate)[0];

  let oldDataTypesArray = listOrigin == 'disabled' ? disabled.slice() : enabled.slice();
  let newDataTypesArray = listOrigin == 'enabled' ? disabled.slice() : enabled.slice();
  newDataTypesArray.sort((a, b) => a.SortOrder - b.SortOrder);

  const oldDataType = oldDataTypesArray.find((dataType) => {
    return dataType.Name == Object.keys(dataTypeToUpdate)[0];
  });
  if (oldDataType == null) {
    const temp = newDataTypesArray.find((dataType) => {
      return dataType.Name == Object.keys(dataTypeToUpdate)[0];
    });
    return;
  }

  const oldIndex = oldDataTypesArray.findIndex((dataType) => {
    return dataType.Name == oldDataType.Name;
  });
  if (listOrigin === 'disabled' || listOrigin === 'enabled') {
    oldDataTypesArray.splice(oldIndex, 1);
    // newDataTypesArray.push(oldDataType);
  }

  const newDataType = {
    ...oldDataType,
    ...dataTypeToUpdate[oldDataType.Name],
  };

  if (!listOrigin) {
    newDataTypesArray.splice(newDataType.SortOrder - 1, 0, newDataType);
    let duplicateIndex = newDataTypesArray.findIndex((item) => {
      return item.Name === newDataType.Name && item.SortOrder === oldDataType.SortOrder;
    });
    newDataTypesArray.splice(duplicateIndex, 1);
  } else {
    newDataTypesArray.splice(dataTypeToUpdate[oldDataType.Name].SortOrder - 1, 0, newDataType);
  }

  newDataTypesArray = newDataTypesArray.map((dataType, index) => {
    return { ...dataType, SortOrder: index + 1 };
  });

  const dataTypesObject = {};
  newDataTypesArray.forEach((dataType) => {
    if (dataType.pk_DepartmentDataType != null || dataType.Enabled) {
      dataTypesObject[dataType.Name] = {
        SortOrder: dataType.Enabled ? dataType.SortOrder : null,
        Enabled: dataType.Enabled,
        pk_DepartmentDataType: dataType.pk_DepartmentDataType,
      };
    }
  });

  if (listOrigin === 'enabled') {
    oldDataTypesArray.forEach((dataType, i) => {
      if (dataType.pk_DepartmentDataType != null) {
        dataType.SortOrder = i + 1;
      }
    });
    oldDataTypesArray.sort((a, b) => a.SortOrder - b.SortOrder);
    oldDataTypesArray.forEach((dataType) => {
      if (dataTypesObject[dataType.Name] == null) {
        if (dataType.pk_DepartmentDataType != null || dataType.Enabled) {
          dataTypesObject[dataType.Name] = {
            SortOrder: dataType.Enabled ? dataType.SortOrder : null,
            Enabled: dataType.Enabled || false,
            pk_DepartmentDataType: dataType.pk_DepartmentDataType,
          };
        }
      }
    });
  }

  return dataTypesObject;
};

export const pdf_concatenate = (options) => {
  const { string = '', maxLimit = 0, forcedCut = false } = options;
  let newString = string;

  if (newString.length > maxLimit) {
    let concat = newString.slice(0, maxLimit - 5);

    while (concat[concat.length - 1] === ' ') {
      concat = concat.slice(0, -1);
    }

    newString = `${concat}${forcedCut ? '' : '. . .'}`;
  }

  return newString;
};

// Give this a field/dataType. It will return aliases for that field, like for 'Email' it could have aliases 'E-mail' and 'Email'
export const getFieldAliases = (field) => {
  const aliasFields = [];

  Object.keys(AAMCIDCSVData).forEach((key) => {
    const item = AAMCIDCSVData[key];
    if (item.matchingObject && item.matchingObject.databaseColumn === field) {
      aliasFields.push(key);
    }
  });
  return aliasFields;
};

export const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }

  if (limit == 0 || wordToTruncate.length <= limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

export const removeTrailingNewLines = (string) => {
  if (string == null) {
    return '';
  }

  let newString = string;

  while (newString[newString.length - 1] === '\n') {
    newString = newString.slice(0, -1);
  }

  return newString;
};

export const calculateFontSize = (options) => {
  const { text, maxWidth, maxHeight, startingFontSize = 12 } = options;
  // Start with a reasonable font size
  let fontSize = startingFontSize;

  // Measure the text size to determine if it fits within the specified dimensions
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  do {
    context.font = `${fontSize}px Arial`; // Use your preferred font
    const textWidth = context.measureText(text).width;
    const textHeight = fontSize;

    // Check if the text fits within the specified dimensions
    if (textWidth <= maxWidth && textHeight <= maxHeight) {
      break;
    }

    // Decrease the font size if the text doesn't fit
    fontSize--;
  } while (fontSize > 0);

  return fontSize;
};

export const isValidIDType = (id = '') => {
  return /\d/.test(id) && id.length < 15;
};

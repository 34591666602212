import {
  faArrowDown19,
  faArrowDown91,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp19,
  faArrowUp91,
  faArrowUpAZ,
  faArrowUpZA,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faCircleCheck,
  faClipboard,
  faDownload,
  faExclamationTriangle,
  faEye,
  faFilter,
  faMarker,
  faMinus,
  faSpinner,
  faSquare,
  faTag,
  faTags,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatBearerToken, processData } from '../../../../../Common.functions';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { clone } from '../../../../../Services/schedule';
import ConfirmModal from '../../../Coordinator/Schedule/ConfirmModal';
import TagPopover from '../TagPopover';
import PreScreenPopover from './PreScreenPopover';
import style from './style';
import './candidateListWithFilters.style.css';
import { useAlert } from 'react-alert';
import Swal from 'sweetalert2';
import Loading from 'Body/Statuses/Loading';
import { CandidateStatusIcons } from 'enums';

let typeTimeout = null;

let selectedIndexes = [];
let selectedCandidates = [];

const CandidateListWithFilters = ({
  dataTypes = [],
  candidates = {},
  getCandidates,
  getAllCandidatesData,
  saveResults,
  deleteCandidatesPermanently,
  evaluators = [],
  token,
  activeSeason,
  dContext,
  isInitializedCandidates,
  isGettingAllDetailedCandidates,
  allCandidatesDetails,
  interviewDates = [],
  allCandidatesDetailsError,
}) => {
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [deletedCandidates, setDeletedCandidates] = useState([]);
  const [csvData, setCSVData] = useState([]);

  const [filter, setFilter] = useState(6);
  const [statusFilter, setStatusFilter] = useState({ label: 'All', value: 6 });
  const [tagFilter, setTagFilter] = useState();
  const [groupsFilter, setGroupsFilter] = useState();

  const [tagChoices, setTagChoices] = useState([]);
  const [groupChoices, setGroupChoices] = useState([]);

  const [userDetails, setUserDetails] = useState();

  const [lastSelectedIndex, setLastSelectedIndex] = useState();
  const [lastSortedField, setLastSortedField] = useState();
  const [lastSortedOrder, setLastSortedOrder] = useState();
  const [previousFilter, setPreviousFilter] = useState(null);

  const [selectAll, setSelectAll] = useState(false);

  const [shiftKeyDown, setShiftKeyDown] = useState(false);

  const [showMarkModal, setShowMarkModal] = useState(false);
  const [showMarkFilter, setShowMarkFilter] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showScheduledNotif, setShowScheduledNotif] = useState(false);
  const [showPreScreen, setShowPreScreen] = useState(false);
  const [showTagsPopover, setShowTagsPopover] = useState(false);
  const [showSelectPopover, setShowSelectPopover] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [showTagFilter, setShowTagFilter] = useState(false);
  const [showGroupFilter, setShowGroupFilter] = useState(false);
  const [showDeletedOnly, setShowDeletedOnly] = useState(false);

  const [showInterviewDatePopover, setShowInterviewDatePopover] = useState();
  const [datesFromSelectedCandidates, setDatesFromselectedCandidates] = useState([]);

  const [stringSelectProperty, setStringSelectProperty] = useState('AAMCID');
  const [selectByString, setSelectBystring] = useState('');
  const [sortStatus, setSortStatus] = useState({ LastName: 'asc' }); // Object containing dataFields and if asc/desc
  const [sortBy, setSortBy] = useState('LastName');
  const [isTyping, setIsTyping] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);

  const defaultSortDescending = ['PrescreenScoreAsDecimal', 'FinalScoreAsDecimal'];

  // Refs below are used because state values don't match up with real time keyboard presses.
  const shiftRef = useRef();
  const lastSelectedRef = useRef();
  const filteredCandidatesRef = useRef();
  const tableHeadersRef = useRef(); // used for downloading csv
  const isUpdatingRef = useRef();

  const oldSelectedCandidatesRef = useRef([]); // Selected candidates before holding shift. Store them here so we can revert back to them when clicking around holding shift.
  const shiftSelectCandidatesRef = useRef([]); // candidates selected while holding shift. Store them here while clicking around holding shift. Combine with oldSelectedCandidates after clicking checkbox with no shift.

  const alert = useAlert();
  const filterOptions = [
    'Deleted',
    'Deleted',
    'Imported',
    'Prescreen',
    'Invite',
    'Rejected (Do not recruit)',
    'All',
    'Registered',
    'Candidate Declined',
  ];

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const statusStyle = { marginRight: 5, cursor: 'pointer' };

  const {
    confirmed,
    invited,
    markedForInvite,
    pendingInvite,
    prescreen,
    rejected,
    scheduled,
    unconfirmed,
    waitlisted,
    deleted,
    declined,
  } = CandidateStatusIcons;

  const orderedFilterOptions = [
    { label: 'All', value: 6 },
    {
      label: 'Waitlisted',
      value: 11,
      icon: (
        <FontAwesomeIcon
          id={`waitlisted_status_filter`}
          icon={waitlisted.icon}
          style={{ color: waitlisted.color, ...statusStyle }}
        />
      ),
    },
    {
      label: 'Prescreen',
      value: 3,
      icon: (
        <FontAwesomeIcon
          id={`prescreen_status_filter`}
          icon={prescreen.icon}
          style={{ color: prescreen.color, ...statusStyle }}
        />
      ),
    },
    {
      label: 'To Invite',
      value: 4,
      icon: (
        <FontAwesomeIcon
          id={`markedForInvite_status_filter`}
          icon={markedForInvite.icon}
          style={{ color: markedForInvite.color, ...statusStyle }}
        />
      ),
    },
    {
      label: 'Invited',
      value: 10,
      icon: (
        <FontAwesomeIcon
          id={`invited_status_filter`}
          icon={invited.icon}
          style={{ color: invited.color, ...statusStyle }}
        />
      ),
    },
    {
      label: 'Registered',
      value: 7,
      icon: (
        <FontAwesomeIcon
          id={`registered_status_filter`}
          icon={scheduled.icon}
          style={{ color: scheduled.color, ...statusStyle }}
        />
      ),
    },
    // {
    //   label: 'Deleted',
    //   value: 1,
    //   icon: (
    //     <FontAwesomeIcon
    //       id={`rejected_status_filter`}
    //       icon={deleted.icon}
    //       style={{ color: deleted.color, ...statusStyle }}
    //     />
    //   ),
    // },
    {
      label: 'Rejected',
      value: 5,
      icon: (
        <FontAwesomeIcon
          id={`rejected_status_filter`}
          icon={rejected.icon}
          style={{ color: rejected.color, ...statusStyle }}
        />
      ),
    },
    {
      label: 'Declined',
      value: 8,
      icon: (
        <FontAwesomeIcon
          id={`rejected_status_filter`}
          icon={declined.icon}
          style={{ color: declined.color, ...statusStyle }}
        />
      ),
    },
  ];

  const { season, department } = dContext;
  const { AllowPrescreen } = season || {};
  const { EnableGroups, ProgramType } = department || {};

  // 0 = Deleted
  // 1 = Removed
  // 2 = Imported
  // 3 = Prescreen
  // 4 = Invite
  // 5 = Decline

  const options = {
    sortName: 'First', //default sort column name
    sortOrder: 'asc', //default sort order
  };

  useEffect(() => {
    isUpdatingRef.current = isUpdating;
    setFilteredCandidates(clone(filteredCandidates));
  }, [isUpdating]);

  useEffect(() => {
    shiftRef.current = shiftKeyDown;
  }, [shiftKeyDown]);

  useEffect(() => {
    if (
      dataTypes &&
      dataTypes.enabled &&
      dataTypes.enabled.length > 0
      // &&
      // candidates &&
      // Object.keys(candidates).length > 0
    ) {
      setToggleSwitch(!toggleSwitch);
      filterCandidates();
    }
  }, [dataTypes]);

  useEffect(() => {
    filterCandidates();
  }, [showDeletedOnly]);
  useEffect(() => {
    filteredCandidatesRef.current = filteredCandidates;
    if (tableHeadersRef.current) {
      processCSVData();
    }
  }, [JSON.stringify(filteredCandidates)]);

  useEffect(() => {
    filterCandidatesByTag(tagFilter);
  }, [filter]);

  useEffect(() => {
    oldSelectedCandidatesRef.current = [];
    shiftSelectCandidatesRef.current = [];
    lastSelectedRef.current = null;
    setSelectedIndexes([]);
    setSelectedCandidates([]);
    filterCandidatesByTag(tagFilter);
    setDeletedCandidates(
      allCandidatesDetails.filter((candidate) => {
        return candidate.isRemoved;
      }),
    );
    // filterCandidates((newFilteredCandidates) => {
    //   sortUpdatedCandidateList({
    //     newFilteredCandidates,
    //     ignoreSortStatus: true,
    //   });
    // });
  }, [allCandidatesDetails]);

  useEffect(() => {
    window.onkeydown = (e) => {
      if (e.keyCode == 16) {
        e.preventDefault();
        setShiftKeyDown(true);
      }
    };

    window.onkeyup = (e) => {
      if (e.keyCode == 16) {
        e.preventDefault();
        setShiftKeyDown(false);
        //   setLastSelectedIndex(null);
      }
    };

    if (dContext && dContext.department && dContext.department.ApplicationSystem === 'sf_match') {
      setStringSelectProperty('SFMatchID');
    }
    setIsUpdating(true);
    getAllCandidatesData(() => {
      setIsUpdating(false);
    });
    getUserDetails();
    getTags();
    getGroups();
  }, []);

  useEffect(() => {
    // Track dates, to warn user when scheduling.
    if (selectedCandidates) {
      const dates = [];
      selectedCandidates.forEach((c) => {
        if (c.pk_InterviewDate) {
          dates.push(c.pk_InterviewDate);
        }
      });
      setDatesFromselectedCandidates(dates);
    }
  }, [JSON.stringify(selectedCandidates)]);

  useEffect(() => {
    if (tagFilter) {
      filterCandidatesByTag(tagFilter);
    }
  }, [tagFilter]);

  useEffect(() => {
    if (groupsFilter) {
      filterCandidatesByGroup(groupsFilter);
    }
  }, [groupsFilter]);

  useEffect(() => {
    setToggleRefresh(!toggleRefresh);
  }, [selectedIndexes, selectedCandidates]);

  const setSelectedIndexes = (newSelectedIndexes) => {
    selectedIndexes = newSelectedIndexes;
  };

  const setSelectedCandidates = (newSelectedCandidates) => {
    selectedCandidates = newSelectedCandidates;
  };

  const processCSVData = () => {
    const headers = []; // ['Tags'];
    const newCSVData = [];

    tableHeadersRef.current.forEach((h) => {
      if (h.dataField !== 'xx' && h.dataField !== 'isSelected') {
        headers.push(h.text);
      }
    });
    newCSVData.push(headers);
    filteredCandidates.forEach((c) => {
      const { Tags = [] } = c;
      let tagString = '';
      Tags.forEach((tag) => {
        if (tag.pk_Tag != null) {
          if (tagString == '') {
            tagString = tag.Tag;
          } else {
            tagString += `, ${tag.Tag}`;
          }
        }
      });
      const newRow = []; //[tagString];

      tableHeadersRef.current.forEach((h) => {
        switch (h.dataField) {
          case 'xx':
            // newRow.push('');
            // Status. Don't render anything.
            break;

          case 'isSelected':
            break;
          case 'createdAt':
            newRow.push(moment(c.createdAt).format('MMM DD, YYYY hh:mm A'));
            break;

          case 'PrescreenScoreAsDecimal':
            newRow.push(c.PrescreenScoreAsDecimal ? (c.PrescreenScoreAsDecimal * 100).toFixed(2) : 0);
            break;

          case 'FinalScoreAsDecimal':
            newRow.push(c.FinalScoreAsDecimal ? (c.FinalScoreAsDecimal * 100).toFixed(2) : 0);
            break;

          case 'DateOfInterview':
            const formattedDate = `${c.DateOfInterview ? moment(c.DateOfInterview).format('MMM DD, YYYY') : ''}${
              c.Label ? ` (${c.Label})` : ''
            }`;
            newRow.push(formattedDate);
            break;

          case 'EvaluatorCount':
            newRow.push(c.PrescreenAssignedEvaluators ? c.PrescreenAssignedEvaluators.length : 0);
            break;

          case 'Tags':
            newRow.push(tagString);
            break;

          case 'Pre-screener':
            const sortedEvaluators = c.PrescreenAssignedEvaluators.slice().sort((a, b) => {
              const textA = `${a.UserLast ? a.UserLast : ''}, ${a.UserFirst ? a.UserFirst : ''}`;
              const textB = `${b.UserLast ? b.UserLast : ''}, ${b.UserFirst ? b.UserFirst : ''}`;
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });

            let preScreenerString = '';
            sortedEvaluators.forEach((evaluator) => {
              if (preScreenerString == '') {
                preScreenerString = `${evaluator.UserFirst ? `${evaluator.UserFirst}` : ''} ${
                  evaluator.UserLast ? evaluator.UserLast : ''
                }`;
              } else {
                preScreenerString += `, ${evaluator.UserFirst ? `${evaluator.UserFirst}` : ''} ${
                  evaluator.UserLast ? evaluator.UserLast : ''
                }`;
              }
            });
            newRow.push(preScreenerString);
            break;
          default:
            newRow.push(c[h.dataField]);
            break;
        }
      });
      newCSVData.push(newRow);
    });

    setCSVData(newCSVData);
  };

  const getUserDetails = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department/user/permissions', null, formatBearerToken(token))
          .then((res) => {
            setUserDetails(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const selectByStringProperty = () => {
    const newFilteredCandidates = clone(filteredCandidates);
    // .replace(/\s/g, '') // Global removal of spaces
    const stringsToSearchFor = selectByString
      .replace(/\s+/g, ',')
      .split(',')
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      }); // To do use \n. repalace comma with \n.

    const newSelectedCandidates = [];
    const newSelectedIndexes = [];
    let selectedCount = 0;
    let toSelectCount = 0;
    stringsToSearchFor.forEach((s) => {
      if (s) {
        toSelectCount++;
      }
    });

    newFilteredCandidates.forEach((c) => {
      c.isSelected = false;
      stringsToSearchFor.forEach((s) => {
        if (!s) {
          return;
        }
        if (stringSelectProperty === 'MedicalSchoolOfGraduation' || stringSelectProperty === 'Email') {
          if (c[stringSelectProperty] && s && c[stringSelectProperty].toLowerCase().indexOf(s.toLowerCase()) >= 0) {
            c.isSelected = true;
            selectedCount++;
            newSelectedCandidates.push(c);
            newSelectedIndexes.push(c.pk_Candidate);
          }
        } else if (c[stringSelectProperty] && s && c[stringSelectProperty].toLowerCase() === s.toLowerCase()) {
          c.isSelected = true;
          selectedCount++;

          newSelectedCandidates.push(c);
          newSelectedIndexes.push(c.pk_Candidate);
        }
      });
    });

    oldSelectedCandidatesRef.current = clone(newSelectedCandidates);
    filteredCandidatesRef.current = clone(newFilteredCandidates);

    setSelectedIndexes(newSelectedIndexes);
    setSelectedCandidates(newSelectedCandidates);
    setStringSelectProperty('AAMCID');
    setSelectBystring('');
    setShowSelectPopover(false);

    setFilteredCandidates(newFilteredCandidates);
    const countDifference = toSelectCount - selectedCount;
    Swal.fire({
      title: 'Selection!',
      text: `Found and selected ${selectedCount} out of ${toSelectCount} ${toSelectCount > 1 ? 'items' : 'item'}! ${
        countDifference == 0
          ? ''
          : `Unable to select ${countDifference} ${countDifference > 1 ? 'items' : 'item'} as ${
              countDifference > 1 ? 'they are' : 'it is'
            } not on the current filtered list.`
      }`,
      type: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: `Ok`,
    });
  };

  const getInterviewDatesDEVTEST = () => {
    return getTokenSilently()
      .then((token) => {
        return fetchDataAgnostic(
          'department/interviewDates',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getTags = () => {
    // setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/tags',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setTagChoices(res.data);
            // setIsLoading(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getGroups = () => {
    // setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'candidate/groups',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setGroupChoices(res.data);
            // setIsLoading(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const scheduleAllCandidatesDEVTEST = async () => {
    const interviewDates = await getInterviewDatesDEVTEST();
    const interviewDatesFormatted = interviewDates.map((interviewDate, i) => {
      const { DateOfInterview, EnableLockedStatus, Label, StartTime, pk_InterviewDate } = interviewDate;
      return {
        DateOfInterview,
        EnableLockedStatus,
        Label,
        StartTime,
        isSelected: true,
        pk_InterviewDate,
        preference: i,
        preferenceOrder: i,
        selected: true,
      };
    });
    const promiseArray = [];
    const promises = selectedCandidates.map((candidate) => {
      const { CandidateUUID } = candidate;
      return postDataAgnostic(
        'candidate/interviewDates',
        { clientID: CandidateUUID, AccessID: '123456' },
        { selectedDates: interviewDatesFormatted },
      )
        .then((results) => {
          return results;
        })
        .catch((err) => {})
        .finally(() => {
          // setIsSending(false);
        });
    });

    return Promise.all(promises)
      .then((result) => {
        alert('Scheudled');
      })
      .catch((err) => {});
  };

  const scheduleSelectedCandidates = (pk_InterviewDate, allowOverbook = false) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'candidate',
          { pk_Department: dContext.department.pk_Department },
          { CandidatePks: selectedIndexes, fieldsToUpdate: { pk_InterviewDate }, allowOverbook },
          formatBearerToken(token),
        )
          .then((results) => {
            // the date was overbooked and did not recieve a boolean of allowOverbook = true
            if (results.data.requiresOverbookPermissions) {
              Swal.fire({
                title: 'Overbook Required',
                text: `The selected date is overbooked. Would you like to overbook the date?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: `No`,
                confirmButtonText: `Yes`,
              }).then((result) => {
                if (result.value) {
                  scheduleSelectedCandidates(pk_InterviewDate, true);
                } else {
                  setIsUpdating(false);
                }
              });
              // setOverbookData({ data: results.data, callback });
              // handleCountUpdate(results.data.candidateCount, fieldsToUpdate.pk_InterviewDate);
              return;
            } else {
              alert.success('Candidates Scheduled Successfully!');

              getAllCandidatesData(() => {
                setIsUpdating(false);
              });
              getCandidates(() => {
                setIsUpdating(false);
              });
            }
          })
          .catch((err) => {
            alert.error('Failed to Schedule Candidates!');
            setIsUpdating(false);
            alert.error(err.message);
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const sortCSVData = (newSortBy, columnIndex) => {
    const newCSVData = clone(csvData);
    const headers = clone(newCSVData[0]);
    newCSVData.splice(0, 1);
    const newSortStatus = clone(sortStatus);
    const order = newSortStatus[newSortBy];
    setSortBy(newSortBy);
    newCSVData.sort((rowA, rowB) => {
      const a = rowA[columnIndex] || '';
      const b = rowB[columnIndex] || '';
      if (a.toUpperCase() > b.toUpperCase()) {
        return order === 'asc' || order == null ? -1 : 1;
      } else if (b.toUpperCase() > a.toUpperCase()) {
        return order === 'asc' || order == null ? 1 : -1;
      } else {
        return 0;
      }
    });

    newCSVData.splice(0, 0, headers);
    setCSVData(newCSVData);
  };

  const filterCandidates = (callback) => {
    const newSelectedIndexes = [];
    const newCandidates = Object.values(allCandidatesDetails);
    const newSelectedCandidates = [];

    // { label: 'Waitlisted', value: 11 },
    // { label: 'Removed', value: 1 },
    // { label: 'Newly Imported', value: 2 }, // Doesn't make sense
    // { label: 'Prescreen', value: 3 },
    // { label: 'To Invite', value: 4 },
    // { label: 'Rejected', value: 5 },
    // { label: 'All', value: 6 },
    // { label: 'Registered', value: 7 },
    // { label: 'Declined', value: 8 },
    // { label: 'Scheduled', value: 9 },
    // { label: 'Invited', value: 10 },

    const newFilteredCandidates = newCandidates.filter((candidate) => {
      const {
        isRemoved,
        isDeleted,
        DateOfInterview,
        IsScheduled,
        EnableMarkedForInvitation,
        EnableMarkedForPrescreen,
        HasBeenSentInvitation,
        HasBeenRejectedForInterview,
        HasDeclinedInterview,
        IsWaitlisted,
      } = candidate;

      const isExisting = (!isRemoved && !isDeleted) || (isRemoved && showDeletedOnly);
      const candidateAddDate = moment(candidate.createdAt);
      const isRecentlyAdded = moment().diff(candidateAddDate, 'hours') < 48;

      const filterConditions = [
        filter == 2 && isExisting && isRecentlyAdded,
        filter == 3 && isExisting && EnableMarkedForPrescreen,
        filter == 4 && isExisting && EnableMarkedForInvitation && !HasBeenSentInvitation,
        filter == 5 && isExisting && HasBeenRejectedForInterview,
        filter == 6 && isExisting,
        filter == 7 && isExisting && DateOfInterview != null,
        filter == 8 && isExisting && HasDeclinedInterview,
        filter == 9 && isExisting && IsScheduled,
        filter == 10 && isExisting && HasBeenSentInvitation,
        filter == 11 && isExisting && IsWaitlisted,
      ];

      if (showDeletedOnly) {
        return isRemoved && filterConditions.some((condition) => condition);
      } else {
        return filterConditions.some((condition) => condition);
      }
    });

    if (callback) {
      callback(newFilteredCandidates);
      return;
    }

    oldSelectedCandidatesRef.current = [];
    setSelectedIndexes(newSelectedIndexes);
    setSelectedCandidates(newSelectedCandidates);
    setSelectAll(false);
    console.log('newFilteredCandidates: ', newFilteredCandidates);

    setFilteredCandidates(newFilteredCandidates);
  };

  const filterCandidatesByTag = (tag) => {
    const newSelectedIndexes = [];

    filterCandidates((newFilteredCandidates) => {
      sortUpdatedCandidateList({
        newFilteredCandidates,
        ignoreSortStatus: true,
        callback: (toFilter) => {
          const newSelectedCandidates = [];
          const tagFilteredCandidates = toFilter.filter((candidate) => {
            const { Tags = [] } = candidate;

            const tagExists = Tags.find((t) => {
              return tag && t.pk_Tag == tag.pk_Tag;
            });

            return tagExists || !tag || tag.pk_Tag == null;
          });

          oldSelectedCandidatesRef.current = [];
          setSelectedIndexes(newSelectedIndexes);
          setSelectedCandidates(newSelectedCandidates);

          setFilteredCandidates(tagFilteredCandidates);
          setSelectAll(false);
        },
      });
    });
  };

  const filterCandidatesByGroup = (group) => {
    const newSelectedIndexes = [];
    if (!group || group.pk_Group == null) {
      filterCandidates();
      return;
    }

    filterCandidates((newFilteredCandidates) => {
      sortUpdatedCandidateList({
        newFilteredCandidates,
        ignoreSortStatus: true,
        callback: (toFilter) => {
          const newSelectedCandidates = [];
          const groupFilteredCandidates = toFilter.filter((candidate) => {
            const { Group } = candidate;

            return Group.pk_Group == group.pk_Group;
          });

          oldSelectedCandidatesRef.current = [];
          setSelectedIndexes(newSelectedIndexes);
          setSelectedCandidates(newSelectedCandidates);

          setFilteredCandidates(groupFilteredCandidates);
          setSelectAll(false);
        },
      });
    });
  };

  /**
   * Sorts the candidate list as per current sorting status. We use this when updating the candidates, to reset the updated list to the current sorting status. We used to lose sorting when updating candidates.
   */
  const sortUpdatedCandidateList = (options) => {
    const { callback, newFilteredCandidates = [], ignoreSortStatus = false } = options;
    const currentSortBy = sortBy;
    const currentSortDirection = sortStatus[currentSortBy];

    onTableSort(currentSortBy, currentSortDirection, newFilteredCandidates, {
      callback: callback,
      ignoreSortStatus,
    });
  };

  const selectAllBetween = (startId, endId, candidateList, willSelect = true) => {
    const newCandidateList = clone(candidateList ? candidateList : filteredCandidates);
    const newSelectedIndexes = []; // clone(selectedIndexes); // [];
    const startIndex =
      startId != null
        ? newCandidateList.findIndex((item) => {
            return item.pk_Candidate == startId;
          })
        : 0;

    const endIndex = newCandidateList.findIndex((item) => {
      return item.pk_Candidate == endId;
    });

    let startIndexActual = 0;
    let endIndexActual = 0;
    if (startIndex > endIndex) {
      startIndexActual = endIndex;
      endIndexActual = startIndex;
    } else {
      startIndexActual = startIndex;
      endIndexActual = endIndex;
    }

    newCandidateList.forEach((candidate, i) => {
      const pk_toAdd = candidate.pk_Candidate;
      if (i >= startIndexActual && i <= endIndexActual) {
        candidate.isSelected = willSelect;

        if (willSelect) {
          if (newSelectedIndexes.indexOf(pk_toAdd) < 0) {
            newSelectedIndexes.push(pk_toAdd);
          }
        } else {
          if (newSelectedIndexes.indexOf(pk_toAdd) >= 0) {
            newSelectedIndexes.splice(newSelectedIndexes.indexOf(pk_toAdd), 1);
          }
        }
      } else if (i < startIndexActual || i > endIndexActual) {
        if (
          oldSelectedCandidatesRef.current.find((c) => {
            return c.pk_Candidate == candidate.pk_Candidate;
          })
        ) {
          candidate.isSelected = true;
          if (newSelectedIndexes.indexOf(pk_toAdd) < 0) {
            newSelectedIndexes.push(pk_toAdd);
          }
        } else {
          candidate.isSelected = false;
          if (newSelectedIndexes.indexOf(pk_toAdd) >= 0) {
            newSelectedIndexes.splice(newSelectedIndexes.indexOf(pk_toAdd), 1);
          }
        }
      }
    });
    window.getSelection().removeAllRanges();
    return { newSelectedIndexes, newCandidateList };
  };

  const deleteSelected = (callback) => {
    const candidatesToDelete = [];

    selectedIndexes.forEach((pk_Candidate) => {
      candidatesToDelete.push(pk_Candidate);
    });

    deleteCandidatesPermanently({
      candidatesToDelete,
      callback: () => {
        setSelectedIndexes([]);
        setSelectedCandidates([]);

        if (callback) {
          callback();
        }
      },
    });
  };

  const markSelected = (mark, callback) => {
    const candidatesToUpdate = [];
    const fieldsToUpdate = {};

    selectedIndexes.forEach((pk_Candidate) => {
      candidatesToUpdate.push(pk_Candidate);
    });

    switch (mark) {
      case 'Prescreen':
        fieldsToUpdate.EnableMarkedForPrescreen = true;
        setFilter(3);
        break;

      case 'Invite':
        fieldsToUpdate.EnableMarkedForInvitation = true;
        fieldsToUpdate.HasBeenSentInvitation = false;
        break;

      case 'Rejected (Do not recruit)':
        fieldsToUpdate.HasBeenRejectedForInterview = true;
        break;

      case 'Remove':
        fieldsToUpdate.isRemoved = true;
        break;

      case 'Delete':
        fieldsToUpdate.isDeleted = true;
      default:
        break;
    }
    setIsUpdating(true);
    saveResults({
      candidatesToUpdate,
      fieldsToUpdate,
      callback: () => {
        if (mark === 'Prescreen') {
        } else {
          setSelectedIndexes([]);
          setSelectedCandidates([]);
        }

        getAllCandidatesData(() => {
          setIsUpdating(false);
        });
        if (callback) {
          callback();
        }
      },
    });
  };

  const restoreSelected = (filter, callback) => {
    // { label: 'Removed', value: 1 },
    // { label: 'Newly Imported', value: 2 }, // Doesn't make sense
    // { label: 'Prescreen', value: 3 },
    // { label: 'To Invite', value: 4 },
    // { label: 'Rejected', value: 5 },
    // { label: 'All', value: 6 },
    // { label: 'Registered', value: 7 },
    // { label: 'Declined', value: 8 },
    // { label: 'Scheduled', value: 9 },
    // { label: 'Invited', value: 10 },

    const candidatesToUpdate = [];
    const fieldsToUpdate = {};

    selectedIndexes.forEach((pk_Candidate) => {
      candidatesToUpdate.push(pk_Candidate);
    });

    switch (parseInt(filter)) {
      case 1:
        fieldsToUpdate.isRemoved = false;
        break;

      case 5:
        fieldsToUpdate.HasBeenRejectedForInterview = false;
        break;

      case 8:
        fieldsToUpdate.HasDeclinedInterview = false;
        break;

      default:
        break;
    }

    if (showDeletedOnly) {
      fieldsToUpdate.isRemoved = false;
    }

    saveResults({
      candidatesToUpdate,
      fieldsToUpdate,
      callback: () => {
        setSelectedIndexes([]);
        setSelectedCandidates([]);

        if (callback) {
          callback();
        }
      },
    });
  };

  const handleCheckBoxClick = (row) => {
    const { isSelected } = row;
    let newSelectedIndexes = selectedIndexes;
    let newSelectedCandidates = selectedCandidates;

    const prevIndex = lastSelectedRef.current;
    let previousClickedCandidate = filteredCandidatesRef.current
      ? filteredCandidatesRef.current.find((candidate) => {
          return candidate.pk_Candidate == prevIndex;
        })
      : {};
    let willSelect = previousClickedCandidate ? previousClickedCandidate.isSelected : true;

    // Check if clicked item is already in the selected list.
    if (selectedIndexes.includes(row.pk_Candidate)) {
      const guiltyIndex = newSelectedIndexes.indexOf(row.pk_Candidate);
      if (guiltyIndex >= 0) {
        newSelectedIndexes.splice(guiltyIndex, 1);
      }
    } else {
      newSelectedIndexes.push(row.pk_Candidate);
    }

    const guiltyIndex2 = newSelectedCandidates.findIndex((c) => {
      return c.pk_Candidate == row.pk_Candidate;
    });
    if (guiltyIndex2 >= 0) {
      newSelectedCandidates.splice(guiltyIndex2, 1);
    } else {
      newSelectedCandidates.push(row);
    }

    if (shiftRef.current) {
      // click with shift
      const { newSelectedIndexes, newCandidateList } = selectAllBetween(
        prevIndex,
        row.pk_Candidate,
        // filteredCandidatesRef.current,
        filteredCandidates,
        willSelect,
      );

      // console.log('click with shift');

      // console.log('newCandidateList: ', clone(newCandidateList.filter((c) => c.isSelected)));
      // console.log('newSelectedIndexes: ', clone(newSelectedIndexes));
      // console.log('oldSelectedCandidatesRef: ', oldSelectedCandidatesRef);
      // console.log('current selected from list: ', clone(filteredCandidates.filter((c) => c.isSelected)));
      let allSelected = true;

      newCandidateList.forEach((c) => {
        if (!c.isSelected) {
          allSelected = false;
        }
        if (c.isSelected && !newSelectedCandidates.find((nc) => nc.pk_Candidate == c.pk_Candidate)) {
          newSelectedCandidates.push(c);
        }

        if (!c.isSelected && newSelectedCandidates.find((nc) => nc.pk_Candidate == c.pk_Candidate)) {
          const guiltyIndex = newSelectedCandidates.findIndex((nc) => nc.pk_Candidate == c.pk_Candidate);

          newSelectedCandidates.splice(guiltyIndex, 1);
        }
      });

      // console.log('newSelectedCandidates: ', clone(newSelectedCandidates));
      shiftSelectCandidatesRef.current = newCandidateList;
      setSelectedIndexes(newSelectedIndexes);
      setSelectedCandidates(newSelectedCandidates);
      setFilteredCandidates(clone(newCandidateList));
      setSelectAll(allSelected);
    } else {
      // click without shift
      newSelectedIndexes = [];

      // initialize/update newSelectedIndexes and newSelectedCandidates with oldSelectedCandidatesRef.current values. This is to counteract the selectedCandidates and selectedIndexes states are sometimes NOT updated when they should be. Example is when selectingByStringProperty.
      // oldSelectedCandidatesRef.current.forEach((c) => {
      //   if (newSelectedCandidates.findIndex((nc) => nc.pk_Candidate == c.pk_Candidate) < 0) {
      //     newSelectedCandidates.push(c);
      //   }

      //   if (!newSelectedIndexes.includes(c.pk_Candidate)) {
      //     newSelectedIndexes.push(c.pk_Candidate);
      //   }
      // });

      // Add selected candidates to selected indexes. This is to make sure selectedCandidates and selectedIndexes match... ?
      newSelectedCandidates.forEach((c) => {
        if (!newSelectedIndexes.includes(c.pk_Candidate)) {
          newSelectedIndexes.push(c.pk_Candidate);
        }
      });
      setLastSelectedIndex(row.pk_Candidate);
      lastSelectedRef.current = row.pk_Candidate;

      // Get updated candidate data from whatever form the list is right now. This is to deal with sorted columns and such.
      const newFilteredCandidates = clone(filteredCandidatesRef.current);
      const newCandidate = newFilteredCandidates.find((c) => {
        return c.pk_Candidate == row.pk_Candidate;
      });
      const selectedIndex = newFilteredCandidates.findIndex((c) => {
        return c.pk_Candidate == row.pk_Candidate;
      });

      const candidateExistsInSelectedCandidates = newSelectedCandidates.findIndex((c) => {
        return c.pk_Candidate == row.pk_Candidate;
      });
      const candidateExistsInSelectedIndexes = newSelectedIndexes.findIndex((c) => {
        return c == row.pk_Candidate;
      });

      if (newCandidate.isSelected) {
        if (candidateExistsInSelectedCandidates >= 0) {
          newSelectedCandidates.splice(candidateExistsInSelectedCandidates, 1);
        }

        if (candidateExistsInSelectedIndexes >= 0) {
          newSelectedIndexes.splice(candidateExistsInSelectedCandidates, 1);
        }
      } else {
        if (candidateExistsInSelectedCandidates < 0) {
          newSelectedCandidates.push(row);
        }

        if (candidateExistsInSelectedIndexes < 0) {
          newSelectedIndexes.push(row.pk_Candidate, 1);
        }
      }

      newCandidate.isSelected = !newCandidate.isSelected;
      newFilteredCandidates[selectedIndex] = newCandidate;
      let allSelected = true;

      newFilteredCandidates.forEach((c) => {
        if (!c.isSelected) {
          allSelected = false;

          // if (newSelectedIndexes.includes(c.pk_Candidate)) {
          //   newSelectedIndexes.splice(newSelectedIndexes.indexOf(c.pk_Candidate), 1);
          // }
        }

        // if (c.isSelected) {
        //   if (!newSelectedIndexes.includes(c.pk_Candidate)) {
        //     newSelectedIndexes.push(c.pk_Candidate);
        //   }
        // }
      });

      setSelectedCandidates(newSelectedCandidates);
      setSelectedIndexes(newSelectedIndexes);

      setFilteredCandidates(newFilteredCandidates);

      oldSelectedCandidatesRef.current = clone(newSelectedCandidates);
      shiftSelectCandidatesRef.current = [];
      setSelectAll(allSelected);
    }
  };

  const markBoolean = (data, callback) => {
    const { booleanString, value } = data;
    const candidatesToUpdate = [];
    const fieldsToUpdate = {};

    selectedIndexes.forEach((pk_Candidate) => {
      candidatesToUpdate.push(pk_Candidate);
    });

    fieldsToUpdate[booleanString] = value;

    setIsUpdating(true);
    saveResults({
      candidatesToUpdate,
      fieldsToUpdate,
      callback: () => {
        setSelectedIndexes([]);
        setSelectedCandidates([]);
        setIsUpdating(false);
        setShowMarkModal(false);
        setIsUpdating(true);
        getAllCandidatesData(() => {
          setIsUpdating(false);
        });
        if (callback) {
          callback();
        }
      },
    });
  };

  const renderTagsForCell = (row) => {
    const { Tags = [] } = row || {};
    return (
      <div style={{ height: 60 }}>
        {Tags.map((tag) => {
          if (tag.pk_Tag != null) {
            return (
              <div>
                <FontAwesomeIcon icon={faTag} style={{ marginRight: 5 }} />
                {tag.Tag}
              </div>
            );
          } else {
          }
        })}
      </div>
    );
  };

  const renderStatusBar = (candidate) => {
    const statuses = [];
    const {
      pk_Candidate,
      EnableMarkedForInvitation,
      EnableMarkedForPrescreen,
      HasBeenSentInvitation,
      IsWaitlisted,
      IsScheduled,
      HasBeenSentConfirmation,
      HasBeenRejectedForInterview,
      DateOfInterview,
    } = candidate;

    if (EnableMarkedForInvitation) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`marked_for_invite_candidateList_${pk_Candidate}`}
            icon={markedForInvite.icon}
            style={{ color: markedForInvite.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`marked_for_invite_candidateList_${pk_Candidate}`}
          >
            Marked for Invite - This candidate was marked for Invitation.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (HasBeenSentInvitation) {
      // Invited, waiting response
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`invited_${pk_Candidate}`}
            icon={invited.icon}
            style={{ color: invited.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`invited_${pk_Candidate}`}
          >
            Invited - This candidate has been sent an invitation.
          </UncontrolledTooltip>
        </>,
      );
    } else if (EnableMarkedForInvitation && !HasBeenSentInvitation && !IsWaitlisted && !IsScheduled) {
      // Pending Invi
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`pending_invite_${pk_Candidate}`}
            icon={pendingInvite.icon}
            style={{ color: pendingInvite.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`pending_invite_${pk_Candidate}`}
          >
            Pending Invite - This candidate was marked for Invitation but has not been sent an invite yet.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (IsWaitlisted) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`waitlisted_${pk_Candidate}`}
            icon={waitlisted.icon}
            style={{ color: waitlisted.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`waitlisted_${pk_Candidate}`}
          >
            Waitlisted - This candidate is currently waitlisted.
          </UncontrolledTooltip>
        </>,
      );
    }
    if (AllowPrescreen && EnableMarkedForPrescreen) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`marked_for_prescreen_${pk_Candidate}`}
            icon={prescreen.icon}
            style={{ color: prescreen.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`marked_for_prescreen_${pk_Candidate}`}
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
          >
            Marked for Prescreen - This candidate is marked for Prescreen.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (IsScheduled) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`confirmation_${pk_Candidate}`}
            icon={HasBeenSentConfirmation ? confirmed.icon : unconfirmed.icon}
            style={{ color: HasBeenSentConfirmation ? confirmed.color : unconfirmed.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`confirmation_${pk_Candidate}`}
          >
            {HasBeenSentConfirmation
              ? 'Confirmation Sent - Candidate has been sent a registration confirmation email.'
              : 'Unconfirmed - Candidate has not been sent a registration confirmation email.'}
          </UncontrolledTooltip>
        </>,
      );

      statuses.push(
        <>
          <FontAwesomeIcon
            id={`scheduled_${pk_Candidate}`}
            icon={scheduled.icon}
            style={{ color: scheduled.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`scheduled_${pk_Candidate}`}
          >
            Scheduled - This candidate is registered for an interview date! (
            {moment(DateOfInterview).format('dddd, MMM. DD, YYYY')})
          </UncontrolledTooltip>
        </>,
      );
    }

    if (HasBeenRejectedForInterview) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`rejected_${pk_Candidate}`}
            icon={rejected.icon}
            style={{ color: rejected.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                behavior: ['top', 'right', 'bottom', 'left'],
                fallbackPlacements: ['top', 'right', 'bottom', 'left'],
              },
            }}
            target={`rejected_${pk_Candidate}`}
          >
            Rejected - This candidate was marked as Rejected by Program.
          </UncontrolledTooltip>
        </>,
      );
    }

    return statuses;
  };

  const renderTagFilter = () => {
    const tags = [{ pk_Tag: null, Tag: 'All' }];
    tagChoices.forEach((tag) => {
      const existsIntags = tags.find((t) => {
        return t.pk_Tag != null && t.pk_Tag == tag.pk_Tag;
      });

      if (existsIntags == null) {
        tags.push({ pk_Tag: tag.pk_Tag, Tag: tag.Tag });
      }
    });

    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        trigger="legacy"
        target="tags_popover_listTab"
        placementPrefix={'filter bs-popover'}
        isOpen={showTagFilter}
        toggle={() => setShowTagFilter(!showTagFilter)}
      >
        <PopoverHeader>
          {' '}
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Tag Filter</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowTagFilter(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={{ height: 300, overflowY: 'auto' }}>
          <div style={style.simpleRow}>
            <div style={style.simpleColumn}>
              {tags.map((tag, i) => {
                const backgroundColor = i % 2 == 0 ? 'white' : '#f2f2f2';

                return (
                  <div
                    style={{
                      ...style.simpleRow,
                      backgroundColor:
                        (tagFilter && tagFilter.pk_Tag == tag.pk_Tag) || (tag.pk_Tag == null && tagFilter == null)
                          ? '#caecfa'
                          : backgroundColor,
                      paddingTop: 5,
                      paddingBottom: 5,
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setTagFilter(tag);
                      setShowTagFilter(false);
                    }}
                  >
                    {tag.Tag}
                  </div>
                );
              })}
            </div>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderGroupFilter = () => {
    const groups = [{ pk_Group: null, Name: 'All' }];
    groupChoices.forEach((group) => {
      const exists = groups.find((g) => {
        return g.pk_Group != null && g.pk_Group == group.pk_Group;
      });

      if (exists == null) {
        groups.push({ pk_Group: group.pk_Group, Name: group.Name });
      }
    });

    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        trigger="legacy"
        target="groups_popover_listTab"
        placementPrefix={'filter bs-popover'}
        isOpen={showGroupFilter}
        toggle={() => setShowGroupFilter(!showGroupFilter)}
      >
        <PopoverHeader>
          {' '}
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Group Filter</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowGroupFilter(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={{ height: 300, overflowY: 'auto' }}>
          <div style={style.simpleRow}>
            <div style={style.simpleColumn}>
              {groups.map((group, i) => {
                const backgroundColor = i % 2 == 0 ? 'white' : '#f2f2f2';

                return (
                  <div
                    style={{
                      ...style.simpleRow,
                      backgroundColor:
                        (groupsFilter && groupsFilter.pk_Group == group.pk_Group) ||
                        (group.pk_Group == null && groupsFilter == null)
                          ? '#caecfa'
                          : backgroundColor,
                      paddingTop: 5,
                      paddingBottom: 5,
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setGroupsFilter(group);
                      setShowTagFilter(false);
                    }}
                  >
                    {group.Name}
                  </div>
                );
              })}
            </div>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderSortCaret = (order, column) => {
    if (order === 'asc') {
      return (
        <>
          <FontAwesomeIcon icon={faCaretUp} style={style.tableHeaderCaret} />
        </>
      );
    } else if (order === 'desc') {
      return (
        <>
          <FontAwesomeIcon icon={faCaretDown} style={style.tableHeaderCaret} />{' '}
        </>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
        </>
      );
    }
  };

  /**
   * Makes columns based off of dataTypes that are enabled. Also added some speciifc columns here.
   * viableCandidates is for when we want to make columns based off of specific properties from candidates, namely, the AdditionalScoring
   */
  const makeColumns = (data) => {
    const columns = [
      {
        isDummyField: true,
        dataField: 'isSelected',
        text: '',
        style: (cell, row, rowIndex) => {
          return {
            ...style.cellStyle({ ...row, rowIndex }),
            fontSize: 21,
            position: 'sticky',
            left: 0,
            zIndex: 1,
            paddingTop: 2,
            paddingBottom: 2,
            width: 40,
          };
        },
        headerStyle: {
          textAlign: 'center',
          width: 'auto',
          cursor: 'pointer',
          position: 'sticky',
          left: 0,
          backgroundColor: '#ffffff',
          verticalAlign: 'middle',
          fontSize: 21,
          zIndex: 1,
          width: 40,
        },
        headerFormatter: headerFormatter,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let { isSelected } = row;

          return (
            <div key={oldSelectedCandidatesRef.current.length}>
              <FontAwesomeIcon
                icon={isSelected ? faCheckSquare : faSquare}
                style={{
                  ...style.clickable,
                  // cursor: isUpdatingRef.current ? 'not-allowed' : 'pointer',
                  // color: isUpdatingRef.current ? 'gray' : null,
                }}
                onClick={() => {
                  const isUpdatingActual = isUpdatingRef.current;
                  // console.log('isUpdatingActual: ', isUpdatingActual);
                  // console.log('isUpdating: ', isUpdating);
                  if (isUpdatingActual) {
                    return;
                  }
                  handleCheckBoxClick(row);
                }}
              />
            </div>
          );
        },
      },
    ];

    columns.push({
      dataField: 'LastName',
      headerStyle: {
        maxWidth: 200,
        minWidth: 200,
        position: 'sticky',
        left: 40,
        zIndex: 3,
        textAlign: 'center',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
        fontStyle: 'bold',
        backgroundColor: 'white',
      },
      sort: true,
      sortOrder: sortStatus['LastName'],
      onSort: onTableSort,
      sortFunc: () => {
        return 0;
      },
      style: (cell, row, rowIndex) => {
        return {
          ...style.cellStyle({ ...row, rowIndex }),
          position: 'sticky',
          left: 40,
          maxWidth: 200,
          minWidth: 200,
          zIndex: 1,
        };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell;
      },
      sortCaret: (order, column) => {
        const { dataField } = column;

        if (sortStatus[dataField] === 'desc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowUpZA} style={style.tableHeaderCaret} />
            </>
          );
        } else if (sortStatus[dataField] === 'asc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowDownAZ} style={style.tableHeaderCaret} />{' '}
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
            </>
          );
        }
      },
      text: 'Last Name',
      headerEvents: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          sortCSVData(column.dataField, columnIndex - 1);
        },
      },
      headerAttrs: { id: 'LastName' },
    });

    columns.push({
      dataField: 'FirstName',
      headerStyle: {
        minWidth: 200,
        maxWidth: 200,
        position: 'sticky',
        left: 240,
        zIndex: 2,
        textAlign: 'center',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
        fontStyle: 'bold',
        backgroundColor: 'white',
      },
      sort: true,
      sortOrder: sortStatus['FirstName'],
      onSort: onTableSort,
      sortFunc: () => {
        return 0;
      },
      style: (cell, row, rowIndex) => {
        return {
          ...style.cellStyle({ ...row, rowIndex }),
          position: 'sticky',
          left: 240,
          zIndex: 1,
          maxWidth: 200,
          minWidth: 200,
        };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell;
      },
      sortCaret: (order, column) => {
        const { dataField } = column;

        if (sortStatus[dataField] === 'desc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowUpZA} style={style.tableHeaderCaret} />
            </>
          );
        } else if (sortStatus[dataField] === 'asc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowDownAZ} style={style.tableHeaderCaret} />{' '}
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
            </>
          );
        }
      },
      text: 'First Name',
      headerEvents: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          sortCSVData(column.dataField, columnIndex - 1);
        },
      },
      headerAttrs: { id: 'FirstName' },
    });

    columns.push({
      dataField: 'Tags',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
        width: 'auto',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
      },
      style: (cell, row, rowIndex) => {
        return style.cellStyle({ ...row, rowIndex });
      },
      text: 'Tags',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return renderTagsForCell(row);
      },
      headerFormatter: (column, colIndex) => {
        return (
          <div style={style.simpleRow} id={'tags_popover_listTab'}>
            <div style={{ ...style.simpleColumn, width: '100%', textAlign: 'center', justifyContent: 'center' }}>
              <div style={{ ...style.simpleRow, justifyContent: 'center' }}>Tags</div>
              <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: 10 }} />
                <div>{tagFilter ? tagFilter.Tag : 'All'}</div>
              </div>
            </div>
            {renderTagFilter()}
          </div>
        );
      },
    });

    if (EnableGroups) {
      columns.push({
        dataField: 'Group',
        headerStyle: {
          minWidth: 200,
          textAlign: 'center',
          width: 'auto',
          cursor: 'pointer',
          position: 'sticky',
          verticalAlign: 'middle',
        },
        style: (cell, row, rowIndex) => {
          return style.cellStyle({ ...row, rowIndex });
        },
        text: 'Groups',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return row.Group ? row.Group.GroupName : '';
          // return renderTagsForCell(row);
        },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={style.simpleRow} id={'groups_popover_listTab'}>
              <div style={{ ...style.simpleColumn, width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>Groups</div>
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <FontAwesomeIcon icon={faFilter} style={{ marginRight: 10 }} />
                  <div>{groupsFilter ? groupsFilter.Name : 'All'}</div>
                </div>
              </div>
              {renderGroupFilter()}
            </div>
          );
        },
      });
    }

    columns.push({
      dataField: 'xx',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
        width: 'auto',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
      },
      // sort: true,
      style: (cell, row, rowIndex) => {
        return style.cellStyle({ ...row, rowIndex });
      },
      headerFormatter: (column, colIndex) => {
        return (
          <div style={style.simpleRow} id={'tags_popover_listTab'}>
            <div style={{ ...style.simpleColumn, width: 350, textAlign: 'center', justifyContent: 'center' }}>
              <div style={{ ...style.simpleRow, justifyContent: 'center' }}>Status</div>
              <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ height: '100%' }}
                      onClick={() => {
                        setShowMarkFilter(!showMarkFilter);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                    </InputGroupText>
                  </InputGroupAddon>

                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ height: '100%' }}
                      onClick={() => {
                        setShowMarkFilter(!showMarkFilter);
                      }}
                    >
                      {statusFilter && statusFilter.icon ? statusFilter.icon : <FontAwesomeIcon icon={faMinus} />}
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    name="markFilter"
                    id="markFilter_header"
                    type="text"
                    style={style.markFilter}
                    value={statusFilter ? statusFilter.label : 'All'}
                    onFocus={(e) => {
                      // setShowMarkFilter(true);
                      e.target.blur();
                    }}
                    // onChange={(e) => {
                    //   setFilter(e.target.value);
                    // }}
                  />
                  <Popover
                    isOpen={showMarkFilter}
                    target="markFilter_header"
                    trigger="legacy"
                    modifiers={{
                      preventOverflow: { boundariesElement: 'viewport' },
                      flip: {
                        behavior: ['top', 'right', 'bottom', 'left'],
                        fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                      },
                    }}
                    toggle={() => {
                      setShowMarkFilter(!showMarkFilter);
                    }}
                  >
                    <PopoverBody>
                      {orderedFilterOptions.map((choice) => {
                        if (choice.label === 'Prescreen' && !AllowPrescreen) return null;
                        return (
                          <Button
                            outline
                            style={{
                              width: '100%',
                              marginBottom: 5,
                              backgroundColor: statusFilter && statusFilter.value == choice.value ? '#d9f5ff' : null,
                            }}
                            color="secondary"
                            onClick={() => {
                              setFilter(choice.value);
                              setStatusFilter(choice);
                              setShowMarkFilter(false);
                            }}
                          >
                            <div
                              key={`${choice.label}_${choice.value}`}
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <div>{choice.icon ? choice.icon : <FontAwesomeIcon icon={faMinus} />}</div>
                              <div style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                                {choice.label}
                              </div>
                            </div>
                          </Button>
                        );
                      })}
                    </PopoverBody>
                  </Popover>
                </InputGroup>
              </div>
            </div>
            {renderTagFilter()}
          </div>
        );
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{renderStatusBar(row)}</div>
        );
      },
      text: 'Status',
    });

    columns.push({
      dataField: 'createdAt',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
        width: 'auto',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
      },
      sort: true,
      sortFunc: () => {
        return 0;
      },
      onSort: onTableSort,
      style: (cell, row, rowIndex) => {
        return style.cellStyle({ ...row, rowIndex });
      },
      text: 'Created',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {row.createdAt ? moment(row.createdAt).format('MMM DD, YYYY hh:mm A') : ''}
          </div>
        );
      },
      sortCaret: (order, column) => {
        const { dataField } = column;

        if (sortStatus[dataField] === 'asc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowDown19} style={style.tableHeaderCaret} />
            </>
          );
        } else if (sortStatus[dataField] === 'desc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowUp91} style={style.tableHeaderCaret} />{' '}
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
            </>
          );
        }
      },
    });

    data.forEach((dataType, i) => {
      const isCustomField = dataType.Name.indexOf('CustomField') == 0;
      if (dataType.Name === 'FirstName' || dataType.Name === 'LastName') {
        return;
      }
      columns.push({
        dataField: dataType.Name,
        headerStyle: {
          minWidth: 200,
          textAlign: 'center',
          width: 'auto',
          cursor: 'pointer',
          position: 'sticky',
          verticalAlign: 'middle',
          fontStyle: isCustomField ? 'italic' : 'bold',
        },
        sort: true,
        sortOrder: dataType.SortOrder,
        onSort: onTableSort,
        sortFunc: () => {
          return 0;
        },
        style: (cell, row, rowIndex) => {
          return style.cellStyle({ ...row, rowIndex });
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <div
              style={{
                height: '100%',
                flexDirection: 'row',
                maxHeight: 200,
                overflowY: 'auto',
                display: 'flex',
              }}
            >
              {cell}
            </div>
          );
        },
        sortCaret: (order, column) => {
          const { rawHeader, dataField } = column;
          const { Name = '' } = rawHeader;

          if (sortStatus[dataField] === 'desc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowUpZA} style={style.tableHeaderCaret} />
                {isCustomField ? (
                  <div>
                    <UncontrolledTooltip
                      modifiers={{
                        preventOverflow: { boundariesElement: 'viewport' },
                        flip: {
                          behavior: ['top', 'right', 'bottom', 'left'],
                          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                        },
                      }}
                      target={Name}
                    >
                      This field is a Custom Field!
                    </UncontrolledTooltip>
                  </div>
                ) : null}
              </>
            );
          } else if (sortStatus[dataField] === 'asc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowDownAZ} style={style.tableHeaderCaret} />{' '}
                {isCustomField ? (
                  <div>
                    <UncontrolledTooltip
                      modifiers={{
                        preventOverflow: { boundariesElement: 'viewport' },
                        flip: {
                          behavior: ['top', 'right', 'bottom', 'left'],
                          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                        },
                      }}
                      target={Name}
                    >
                      This field is a Custom Field!
                    </UncontrolledTooltip>
                  </div>
                ) : null}
              </>
            );
          } else {
            return (
              <>
                <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
                {isCustomField ? (
                  <div>
                    <UncontrolledTooltip
                      modifiers={{
                        preventOverflow: { boundariesElement: 'viewport' },
                        flip: {
                          behavior: ['top', 'right', 'bottom', 'left'],
                          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                        },
                      }}
                      target={Name}
                    >
                      This field is a Custom Field!
                    </UncontrolledTooltip>
                  </div>
                ) : null}
              </>
            );
          }
        },
        rawHeader: dataType,
        text: dataType.Alias || dataType.Name,
        headerEvents: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            sortCSVData(column.dataField, columnIndex - 1);
          },
        },
        headerAttrs: { id: dataType.Name },
      });
    });

    // Sort Columns by SortOrder
    columns.sort((a, b) => {
      return a.SortOrder - b.SortOrder;
    });

    // if (filter == 3) {

    if (AllowPrescreen) {
      columns.splice(3, 0, {
        dataField: 'Pre-screener',
        headerStyle: {
          minWidth: 200,
          textAlign: 'center',
          width: 'auto',
          cursor: 'pointer',
          position: 'sticky',
          verticalAlign: 'middle',
        },
        sort: true,
        onSort: onTableSort,
        style: (cell, row, rowIndex) => {
          return style.cellStyle({ ...row, rowIndex });
        },
        text: 'Prescreen Evaluators',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          const sortedEvaluators = row.PrescreenAssignedEvaluators.slice().sort((a, b) => {
            const textA = `${a.UserLast ? a.UserLast : ''}, ${a.UserFirst ? a.UserFirst : ''}`;
            const textB = `${b.UserLast ? b.UserLast : ''}, ${b.UserFirst ? b.UserFirst : ''}`;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          return (
            <div
              style={{
                height: '100%',
                flexDirection: 'row',
                maxHeight: 200,
                overflowY: 'auto',
                display: 'flex',
              }}
            >
              <div>
                {sortedEvaluators.map((evaluator) => {
                  return <div>{`${evaluator.UserLast}, ${evaluator.UserFirst}`}</div>;
                })}
              </div>
            </div>
          );
        },
        sortCaret: (order, column) => {
          const { dataField } = column;

          if (sortStatus[dataField] === 'asc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowDownAZ} style={style.tableHeaderCaret} />
              </>
            );
          } else if (sortStatus[dataField] === 'desc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowUpZA} style={style.tableHeaderCaret} />{' '}
              </>
            );
          } else {
            return (
              <>
                <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
              </>
            );
          }
        },
      });
      columns.splice(4, 0, {
        dataField: 'EvaluatorCount',
        isDummyField: true,
        headerStyle: {
          minWidth: 100,
          textAlign: 'center',
          width: 'auto',
          cursor: 'pointer',
          position: 'sticky',
          verticalAlign: 'middle',
        },
        sort: true,
        onSort: onTableSort,
        style: (cell, row, rowIndex) => {
          return style.cellStyle({ ...row, rowIndex });
        },
        text: 'Evaluator Count',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {row.PrescreenAssignedEvaluators != null ? row.PrescreenAssignedEvaluators.length : '-'}
            </div>
          );
        },
        sortCaret: (order, column) => {
          const { dataField } = column;

          if (sortStatus[dataField] === 'asc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowDown19} style={style.tableHeaderCaret} />
              </>
            );
          } else if (sortStatus[dataField] === 'desc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowUp91} style={style.tableHeaderCaret} />{' '}
              </>
            );
          } else {
            return (
              <>
                <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
              </>
            );
          }
        },
      });
    }

    // }

    if (columns.length == 0) {
      columns.push({
        isDummyField: true,
        dataField: 'towad',
        text: '',
      });
    }

    columns.push({
      dataField: 'DateOfInterview',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
        width: 'auto',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
      },
      sort: true,
      onSort: onTableSort,
      sortFunc: () => {
        return 0;
      },
      style: (cell, row, rowIndex) => {
        return style.cellStyle({ ...row, rowIndex });
      },
      text: 'Interview Date',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <div> {cell ? moment(cell).format('MMMM DD, YYYY') : ''}</div>
            <div>{row.Label ? `(${row.Label})` : ''}</div>
          </div>
        );
      },
      sortCaret: (order, column) => {
        const { dataField } = column;

        if (sortStatus[dataField] === 'asc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowDown19} style={style.tableHeaderCaret} />
            </>
          );
        } else if (sortStatus[dataField] === 'desc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowUp91} style={style.tableHeaderCaret} />{' '}
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
            </>
          );
        }
      },
    });

    if (dContext && dContext.season && dContext.season.AllowPrescreen) {
      columns.push({
        dataField: 'PrescreenScoreAsDecimal',
        headerStyle: {
          minWidth: 200,
          textAlign: 'center',
          width: 'auto',
          cursor: 'pointer',
          position: 'sticky',
          verticalAlign: 'middle',
        },
        sort: true,
        onSort: onTableSort,
        style: (cell, row, rowIndex) => {
          return style.cellStyle({ ...row, rowIndex });
        },
        text: 'Prescreen Score',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: 'center' }}>{cell != null ? (parseFloat(cell) * 100).toFixed(2) : '-'}</div>;
        },
        sortCaret: (order, column) => {
          const { dataField } = column;

          if (sortStatus[dataField] === 'asc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowDown19} style={style.tableHeaderCaret} />
              </>
            );
          } else if (sortStatus[dataField] === 'desc') {
            return (
              <>
                <FontAwesomeIcon icon={faArrowUp91} style={style.tableHeaderCaret} />{' '}
              </>
            );
          } else {
            return (
              <>
                <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
              </>
            );
          }
        },
      });
    }

    columns.push({
      dataField: 'FinalScoreAsDecimal',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
        width: 'auto',
        cursor: 'pointer',
        position: 'sticky',
        verticalAlign: 'middle',
      },
      sort: true,
      style: (cell, row, rowIndex) => {
        return style.cellStyle({ ...row, rowIndex });
      },
      onSort: onTableSort,
      text: 'Evaluation Score',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <div style={{ textAlign: 'center' }}>{cell != null ? (parseFloat(cell) * 100).toFixed(2) : '-'}</div>;
      },
      sortCaret: (order, column) => {
        const { dataField } = column;

        if (sortStatus[dataField] === 'asc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowDown19} style={style.tableHeaderCaret} />
            </>
          );
        } else if (sortStatus[dataField] === 'desc') {
          return (
            <>
              <FontAwesomeIcon icon={faArrowUp91} style={style.tableHeaderCaret} />{' '}
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon icon={faMinus} style={style.tableHeaderCaret} />
            </>
          );
        }
      },
    });

    tableHeadersRef.current = columns;
    return columns;
  };

  const onTableSort = (field, rawOrder, forcedCandidates, options) => {
    const { callback, ignoreSortStatus } = options || {};

    let order = rawOrder;

    if (ignoreSortStatus) {
      // do nothing, order is forced to be rawOrder
    } else if (sortBy != field) {
      if (!defaultSortDescending.includes(field)) {
        order = 'asc';
      }
    } else {
      if (!defaultSortDescending.includes(field)) {
        order = sortStatus[field] === 'asc' ? 'desc' : 'asc';
      }
    }

    let newFilteredCandidates = clone(forcedCandidates || filteredCandidates);
    if (field === 'PrescreenScoreAsDecimal' || field === 'FinalScoreAsDecimal') {
      newFilteredCandidates.sort((a, b) => {
        if (a[field] == null || a[field] == undefined) {
          return -1;
        }

        if (b[field] == null || b[field] == undefined) {
          return 1;
        }
        return a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0;
      });
    } else if (field === 'createdAt') {
      newFilteredCandidates.sort((a, b) => {
        let dateA = moment(a[field]);
        let dateB = moment(b[field]);

        if (!a[field]) {
          return -1;
        } else if (!b[field]) {
          return 1;
        }

        if (order === 'desc') {
          if (dateA.isBefore(dateB)) {
            return -1;
          } else if (dateB.isBefore(dateA)) {
            return 1;
          } else {
            return a.pk_Candidate - b.pk_Candidate;
          }
        } else {
          if (dateB.isBefore(dateA)) {
            return -1;
          } else if (dateA.isBefore(dateB)) {
            return 1;
          } else {
            return b.pk_Candidate - a.pk_Candidate;
          }
        }
      });
    } else if (field === 'DateOfInterview') {
      // Sort by DateOfInterview
      newFilteredCandidates.sort((a, b) => {
        let dateA = moment(`${a[field]} ${a.StartTime || '8:00'}`);
        let dateB = moment(`${b[field]} ${b.StartTime || '8:00'}`);

        if (order === 'asc') {
          if (!b[field]) {
            return -1;
          }
          if (!a[field]) {
            return 1;
          }

          if (dateA.isBefore(dateB)) {
            return -1;
          } else if (dateB.isBefore(dateA)) {
            return 1;
          } else {
            return b.pk_Candidate - a.pk_Candidate;
          }
        } else {
          if (!b[field]) {
            return -1;
          }
          if (!a[field]) {
            return 1;
          }

          if (dateB.isBefore(dateA)) {
            return -1;
          } else if (dateA.isBefore(dateB)) {
            return 1;
          } else {
            return a.pk_Candidate - b.pk_Candidate;
          }
        }
      });
    } else if (field === 'EvaluatorCount') {
      newFilteredCandidates.sort((a, b) => {
        let aValue = a.PrescreenAssignedEvaluators ? a.PrescreenAssignedEvaluators.length : null;
        let bValue = b.PrescreenAssignedEvaluators ? b.PrescreenAssignedEvaluators.length : null;

        if (aValue == null) {
          return -1;
        } else if (bValue == null) {
          return 1;
        }

        if (order === 'asc') {
          return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
        } else {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        }
      });
    } else if (field === 'Pre-screener') {
      newFilteredCandidates.sort((a, b) => {
        let textA = null;
        let textB = null;
        if (a.PrescreenAssignedEvaluators) {
          a.PrescreenAssignedEvaluators.sort((a, b) => {
            const textA = `${a.UserLast ? a.UserLast : ''}, ${a.UserFirst ? a.UserFirst : ''}`;
            const textB = `${b.UserLast ? b.UserLast : ''}, ${b.UserFirst ? b.UserFirst : ''}`;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }).forEach((e) => {
            if (textA == null) {
              textA = `${e.UserLast}, ${e.UserFirst}`;
            } else {
              textA += `, ${e.UserLast}, ${e.UserFirst}`;
            }
          });
        }

        if (b.PrescreenAssignedEvaluators) {
          b.PrescreenAssignedEvaluators.sort((a, b) => {
            const textA = `${a.UserLast ? a.UserLast : ''}, ${a.UserFirst ? a.UserFirst : ''}`;
            const textB = `${b.UserLast ? b.UserLast : ''}, ${b.UserFirst ? b.UserFirst : ''}`;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }).forEach((e) => {
            if (textB == null) {
              textB = `${e.UserLast}, ${e.UserFirst}`;
            } else {
              textB += `, ${e.UserLast}, ${e.UserFirst}`;
            }
          });
        }

        if (textA == null) {
          return 1;
        }

        if (textB == null) {
          return -1;
        }

        if (order === 'desc') {
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        } else {
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
      });
    } else {
      newFilteredCandidates.sort((a, b) => {
        let textA = a[field] ? a[field].toUpperCase() : '';
        let textB = b[field] ? b[field].toUpperCase() : '';

        let valueToReturn = 0;

        if (order === 'asc') {
          if (!a[field]) {
            valueToReturn = 1;
          } else if (!b[field]) {
            valueToReturn = -1;
          } else {
            valueToReturn = textA < textB ? -1 : textA > textB ? 1 : 0;
          }
        } else {
          if (!a[field]) {
            valueToReturn = -1;
          } else if (!b[field]) {
            valueToReturn = 1;
          } else {
            valueToReturn = textA < textB ? 1 : textA > textB ? -1 : 0;
          }
        }

        if (valueToReturn == 0) {
          if (field === 'LastName') {
            const textA = a['FirstName'] ? a['FirstName'].toUpperCase() : '';
            const textB = b['FirstName'] ? b['FirstName'].toUpperCase() : '';

            if (order === 'asc') {
              if (!textA) {
                valueToReturn = 1;
              } else if (!textB) {
                valueToReturn = -1;
              } else {
                valueToReturn = textA < textB ? -1 : textA > textB ? 1 : 0;
              }
            } else {
              if (!textA) {
                valueToReturn = -1;
              } else if (!textB) {
                valueToReturn = 1;
              } else {
                valueToReturn = textA < textB ? 1 : textA > textB ? -1 : 0;
              }
            }
          }

          if (field === 'FirstName') {
            const textA = a['LastName'] ? a['LastName'].toUpperCase() : '';
            const textB = b['LastName'] ? b['LastName'].toUpperCase() : '';

            if (order === 'asc') {
              if (!textA) {
                valueToReturn = 1;
              } else if (!textB) {
                valueToReturn = -1;
              } else {
                valueToReturn = textA < textB ? -1 : textA > textB ? 1 : 0;
              }
            } else {
              if (!textA) {
                valueToReturn = 1;
              } else if (!textB) {
                valueToReturn = -1;
              } else {
                valueToReturn = textA < textB ? 1 : textA > textB ? -1 : 0;
              }
            }
          }
        }

        return valueToReturn;
      });
    }

    if (callback) {
      callback(newFilteredCandidates);
      return;
    }
    setSortBy(field);
    setSortStatus({ [field]: order });
    filteredCandidatesRef.current = newFilteredCandidates;
    setFilteredCandidates(newFilteredCandidates);
    setLastSortedField(field);
    setLastSortedOrder(order);
  };

  const renderMarkPopover = () => {
    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        placementPrefix="markPopover bs-popover"
        isOpen={showMarkModal}
        trigger="legacy"
        target="mark_button"
        toggle={() => {
          if (selectedCandidates && selectedCandidates.length > 0) {
            setShowMarkModal(!showMarkModal);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Mark Selected as: </div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowMarkModal(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div style={style.simpleColumn}>
            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <div style={{ ...style.simpleColumn, minWidth: 100, width: '30%', fontWeight: 'bold' }}>Prescreen: </div>
              <div style={{ ...style.simpleColumn, width: '70%' }}>
                <div style={{ ...style.simpleRow, justifyContent: 'right' }}>
                  <div id="prescreen_mark">
                    <Button
                      disabled={isUpdating || !AllowPrescreen}
                      size="sm"
                      color="success"
                      style={{ width: 50, marginRight: 10 }}
                      onClick={() => {
                        markBoolean({ booleanString: 'EnableMarkedForPrescreen', value: true });
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                  </div>

                  <div id="prescreen_unmark">
                    <Button
                      disabled={isUpdating || !AllowPrescreen}
                      size="sm"
                      color="danger"
                      style={{ width: 50 }}
                      onClick={() => {
                        markBoolean({ booleanString: 'EnableMarkedForPrescreen', value: false });
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>

                  <UncontrolledTooltip
                    modifiers={{
                      preventOverflow: { boundariesElement: 'viewport' },
                      flip: {
                        behavior: ['top', 'right', 'bottom', 'left'],
                        fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                      },
                    }}
                    target="prescreen_mark"
                  >
                    Mark for Prescreen.
                    {` `}
                    {!AllowPrescreen ? 'Cannot modify - Prescreen with RezRATE is Disabled.' : ''}
                  </UncontrolledTooltip>
                  <UncontrolledTooltip
                    modifiers={{
                      preventOverflow: { boundariesElement: 'viewport' },
                      flip: {
                        behavior: ['top', 'right', 'bottom', 'left'],
                        fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                      },
                    }}
                    target="prescreen_unmark"
                  >
                    Unmark for Prescreen. {` `}
                    {!AllowPrescreen ? 'Cannot modify - Prescreen with RezRATE is Disabled.' : ''}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <div style={{ ...style.simpleColumn, minWidth: 100, width: '30%', fontWeight: 'bold' }}>Invitation: </div>
              <div style={{ ...style.simpleColumn, width: '70%' }}>
                <div style={{ ...style.simpleRow, justifyContent: 'right' }}>
                  <div id="invitation_mark">
                    <Button
                      disabled={isUpdating || !AllowPrescreen}
                      size="sm"
                      color="success"
                      style={{ width: 50, marginRight: 10 }}
                      onClick={() => {
                        markBoolean({ booleanString: 'EnableMarkedForInvitation', value: true });
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                  </div>
                  <div id="invitation_unmark">
                    <Button
                      disabled={isUpdating || !AllowPrescreen}
                      size="sm"
                      color="danger"
                      style={{ width: 50 }}
                      onClick={() => {
                        markBoolean({ booleanString: 'EnableMarkedForInvitation', value: false });
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>

                  <UncontrolledTooltip
                    modifiers={{
                      preventOverflow: { boundariesElement: 'viewport' },
                      flip: {
                        behavior: ['top', 'right', 'bottom', 'left'],
                        fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                      },
                    }}
                    target="invitation_mark"
                  >
                    Mark for Invitation. {` `}
                    {!AllowPrescreen ? 'Cannot modify - Prescreen with RezRATE is Disabled.' : ''}
                  </UncontrolledTooltip>
                  <UncontrolledTooltip
                    modifiers={{
                      preventOverflow: { boundariesElement: 'viewport' },
                      flip: {
                        behavior: ['top', 'right', 'bottom', 'left'],
                        fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                      },
                    }}
                    target="invitation_unmark"
                  >
                    Unmark for Invitation. {` `}
                    {!AllowPrescreen ? 'Cannot modify - Prescreen with RezRATE is Disabled.' : ''}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              id="rejection_mark"
              disabled={isUpdating}
              color="primary"
              style={{ width: '100%', marginBottom: 10 }}
              onClick={() => {
                markSelected('Rejected (Do not recruit)');

                setShowMarkModal(false);
              }}
            >
              <div style={style.spacedBetweenRow}>
                <div style={style.buttonLabel}>Rejected (Do not recruit)</div>
              </div>
            </Button>
            <UncontrolledTooltip
              modifiers={{
                preventOverflow: { boundariesElement: 'viewport' },
                flip: {
                  behavior: ['top', 'right', 'bottom', 'left'],
                  fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                },
              }}
              target="rejection_mark"
            >
              Mark for Rejection
            </UncontrolledTooltip>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderDeleteConfirmation = () => {
    return (
      <ConfirmModal
        title={`Permanently Delete Selected ${selectedCandidates.length > 1 ? 'Items' : 'Item'}`}
        message={`Are you sure you want to permanently delete selected ${
          selectedCandidates.length > 1 ? 'items' : 'item'
        }?`}
        isOpen={showConfirmDelete}
        buttonChoices={
          <>
            <Button color="secondary" style={{ ...style.genButton, backgroundColor: null }}>
              <div
                style={{ ...style.buttonLabel, margin: 0 }}
                onClick={() => {
                  setShowConfirmDelete(false);
                }}
              >
                Cancel
              </div>
            </Button>
            <Button
              color="danger"
              style={{ ...style.genButton, backgroundColor: null }}
              onClick={() => {
                setIsUpdating(true);
                deleteSelected(() => {
                  setShowConfirmDelete(false);
                  getAllCandidatesData(() => {
                    setIsUpdating(false);
                  });
                });
              }}
            >
              <div style={{ ...style.buttonLabel, margin: 0 }}>Yes</div>
            </Button>
          </>
        }
      />
    );
  };

  const renderRemoveConfirmation = () => {
    return (
      <ConfirmModal
        title={`Deactivate Selected ${selectedCandidates.length > 1 ? 'Items' : 'Item'}`}
        message={`Are you sure you want to deactivate selected ${selectedCandidates.length > 1 ? 'items' : 'item'}?`}
        isOpen={showConfirmRemove}
        buttonChoices={
          <>
            <Button color="secondary" style={{ ...style.genButton, backgroundColor: null }}>
              <div
                style={{ ...style.buttonLabel, margin: 0 }}
                onClick={() => {
                  setShowConfirmRemove(false);
                }}
              >
                Cancel
              </div>
            </Button>
            <Button
              color="danger"
              style={{ ...style.genButton, backgroundColor: null }}
              onClick={() => {
                markSelected('Remove', () => {
                  setShowConfirmRemove(false);
                });
              }}
            >
              <div style={{ ...style.buttonLabel, margin: 0 }}>Yes</div>
            </Button>
          </>
        }
      />
    );
  };

  const renderScheduledNotif = () => {
    const scheduledCandidates = selectedCandidates.filter((candidate) => {
      return candidate.DateOfInterview != null;
    });
    let message =
      'Some Candidates have scheduled Interview dates already! Unschedule them before editing their Status. Candidates with Interview Dates: \n';
    scheduledCandidates.forEach((candidate, i) => {
      if (i > 0) {
        message += `, ${candidate.LastName}`;
      } else {
        message += candidate.LastName;
      }
    });
    return (
      <ConfirmModal
        title={'Locked Items!'}
        message={message}
        buttonChoices={
          <Button
            color="primary"
            onClick={() => {
              setShowScheduledNotif(false);
            }}
          >
            Ok
          </Button>
        }
        isOpen={showScheduledNotif}
      />
    );
  };

  const renderPreScreenList = () => {
    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        popperClassName="preScreenPopover"
        isOpen={showPreScreen}
        trigger="legacy"
        target="pre_screen_button"
        toggle={() => {
          if (selectedCandidates && selectedCandidates.length > 0) {
            setShowPreScreen(!showPreScreen);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Assign Evaluators </div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowPreScreen(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <PreScreenPopover
            evaluators={evaluators}
            token={token}
            selectedCandidates={selectedIndexes}
            activeSeason={activeSeason}
            setShowPreScreen={setShowPreScreen}
            getAllCandidatesData={getAllCandidatesData}
            getCandidates={getCandidates}
            setIsUpdating={setIsUpdating}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderTagsPopover = () => {
    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        popperClassName="markPopover"
        isOpen={showTagsPopover}
        trigger="legacy"
        target="tags_button"
        toggle={(e) => {
          if (typeof e.target.className === 'string' && e.target.className.indexOf('swal') >= 0) {
            return;
          }
          if (selectedCandidates && selectedCandidates.length > 0) {
            setShowTagsPopover(!showTagsPopover);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Tags</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowTagsPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <TagPopover
            token={token}
            selectedCandidates={selectedCandidates}
            getCandidates={getCandidates}
            getAllCandidatesData={() => {
              setIsUpdating(true);
              getAllCandidatesData(() => {
                setIsUpdating(false);
              });
            }}
            setShowPopover={setShowTagsPopover}
            setParentTags={(newTags) => {
              setTagChoices(newTags);
            }}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderSelectPopover = () => {
    const fieldsToSelect = [
      {
        label: 'AAMC ID',
        value: 'AAMCID',
      },
      { label: 'SF Match ID', value: 'SFMatchID', deptSettingsKey: 'sf_match' },
      { label: 'NRMP ID', value: 'NRMPID' },
      { label: 'Medical School', value: 'MedicalSchoolOfGraduation' },
      { label: 'Email', value: 'Email' },
    ];
    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        placementPrefix="bs-popover markPopover"
        isOpen={showSelectPopover}
        trigger="legacy"
        target="select_by"
        toggle={() => {
          setShowSelectPopover(!showSelectPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Select by Field</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowSelectPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div>
            <div style={{ marginBottom: 10 }}>
              <Input
                type="select"
                value={stringSelectProperty}
                rows={6}
                onChange={(e) => {
                  setStringSelectProperty(e.target.value);
                }}
              >
                {fieldsToSelect.map((o) => {
                  return <option value={o.value}>{o.label}</option>;
                })}
              </Input>
            </div>

            <div>
              <Input
                type="textarea"
                defaultValue={selectByString || ''}
                rows={6}
                placeholder={`${stringSelectProperty || 'ID'} here. . . \nex: ID1,ID2,ID3. . .`}
                onChange={(e) => {
                  const newValue = e.target.value;
                  clearTimeout(typeTimeout);
                  if (!isTyping) {
                    setIsTyping(true);
                  }

                  typeTimeout = setTimeout(() => {
                    setSelectBystring(newValue);
                    setIsTyping(false);
                    // clearTimeout(typeTimeout);
                    // typeTimeout = null;
                  }, 500);
                }}
              />
            </div>

            <div
              style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}
            >
              <Button
                color="success"
                disabled={!selectByString || isTyping}
                onClick={() => {
                  selectByStringProperty();
                }}
              >
                {' '}
                Select Items!
              </Button>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderDatePickerPopover = () => {
    return (
      <Popover
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
          flip: {
            behavior: ['top', 'right', 'bottom', 'left'],
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }}
        placementPrefix="filterPopover bs-popover"
        isOpen={showInterviewDatePopover}
        trigger="legacy"
        target="date_scheduler"
        toggle={() => {
          setShowInterviewDatePopover(!showInterviewDatePopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div>Available Interview Sessions</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInterviewDatePopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div>
            <div style={{ height: 410, overflowY: 'auto' }}>
              {interviewDates.map((interviewDate, i) => {
                const { CandidateRegistrationLimit = 0, CountOfCandidates = 0 } = interviewDate || {};
                const backgroundColor = i % 2 === 0 ? '#c7cbd1' : 'white';
                return (
                  <div
                    style={{ ...style.dateListItem, backgroundColor }}
                    onClick={() => {
                      // setShowInterviewDatePopover(false);

                      Swal.fire({
                        title: 'Alert!',
                        text: `Bulk assigning candidates to a session ignores registration limits. Are you sure you want to proceed?`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: `Yes, I Agree`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const datesThatAreNotThisDate = datesFromSelectedCandidates.filter(
                            (d) => d != interviewDate.pk_InterviewDate,
                          );

                          if (datesThatAreNotThisDate.length > 0) {
                            Swal.fire({
                              title: 'Modify Registration?',
                              text: `${datesThatAreNotThisDate.length} selected candidate${
                                datesThatAreNotThisDate.length > 1 ? 's' : ''
                              } are already registered for a different date.
                              `,
                              icon: 'warning',
                              showCancelButton: true,
                              cancelButtonColor: 'gray',
                              confirmButtonColor: '#d33',
                              confirmButtonText: `Yes, Modify`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                scheduleSelectedCandidates(interviewDate.pk_InterviewDate);
                                // SCHEDULE!
                                setShowInterviewDatePopover(false);
                              } else {
                                // CANCEL!
                                setShowInterviewDatePopover(false);
                              }
                            });
                          } else {
                            scheduleSelectedCandidates(interviewDate.pk_InterviewDate);
                            // SCHEDULE!
                            setShowInterviewDatePopover(false);
                          }
                          // scheduleCandidatesForInterviewDate(interviewDate);
                        }
                      });
                    }}
                  >
                    <div>
                      {`${moment
                        .tz(interviewDate.DateOfInterview, null)
                        .format('dddd, MMM. DD, YYYY')} (${CountOfCandidates || 0}/${CandidateRegistrationLimit || 0})`}
                    </div>
                    <div> {interviewDate.Label}</div>
                  </div>
                );
              })}
            </div>
            {/* <div style={style.simpleRow}>
              <Button style={{ ...style.genButton, backgroundColor: '#ffbb00' }}>
                <div style={style.spacedBetweenRow}>
                  <div>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                  <div style={style.buttonLabel}>Manage Interview Dates</div>
                </div>
              </Button>
            </div> */}
            {/* <div style={style.simpleRow}>
              <Button
                onClick={() => {
                  Swal.fire({
                    title: 'Warning!',
                    text: `Are you sure you want to un-register ${selectedIndexes.length} Candidate${
                      selectedIndexes.length > 1 ? 's' : ''
                    }?`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: 'red',
                    confirmButtonText: `Unregister`,
                  }).then((result) => {
                    if (result.value) {
                      unscheduleCandidates();
                    }
                  });
                }}
                color="danger"
                style={{ ...style.genButton, backgroundColor: null, width: '100%', marginTop: 10 }}
              >
                <div style={style.spacedBetweenRow}>
                  <div>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                  <div style={{ ...style.buttonLabel, textAlign: 'center' }}>Unregister</div>
                </div>
              </Button>
            </div> */}
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const headerFormatter = (column, colIndex) => {
    // Add Sort logic here
    return (
      <div style={{ alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle' }}>
        <FontAwesomeIcon
          icon={selectAll ? faCheckSquare : faSquare}
          style={style.clickable}
          onClick={() => {
            const isUpdatingActual = isUpdatingRef.current;
            if (isUpdatingActual) {
              return;
            }
            const newFilteredCandidates = clone(filteredCandidatesRef.current);
            const newSelectedIndexes = clone(selectedIndexes);
            const newSelectedCandidates = clone(selectedCandidates);

            newFilteredCandidates.forEach((c) => {
              c.isSelected = !selectAll;
              if (!selectAll && !newSelectedIndexes.includes(c.pk_Candidate)) {
                newSelectedIndexes.push(c.pk_Candidate);
                newSelectedCandidates.push(c);
              } else if (selectAll && newSelectedIndexes.includes(c.pk_Candidate)) {
                const guiltyIndex = newSelectedIndexes.indexOf(c.pk_Candidate);
                newSelectedIndexes.splice(guiltyIndex);
                newSelectedCandidates.splice(guiltyIndex);
              }
            });

            oldSelectedCandidatesRef.current = newSelectedCandidates;
            setSelectedIndexes(newSelectedIndexes);
            setSelectedCandidates(newSelectedCandidates);
            setSelectAll(!selectAll);
            setFilteredCandidates(newFilteredCandidates);
          }}
        />
      </div>
    );
  };

  const renderIndicatorLabel = () => {
    let color = 'green';
    let icon = faCircleCheck;
    let message = 'Done.';

    if (isGettingAllDetailedCandidates) {
      if (Object.values(allCandidatesDetails).length > 0) {
        message = 'Updating candidate data. . .';
        icon = faSpinner;
        color = 'gray';
      } else {
        message = 'Loading candidate data. . .';
        icon = faSpinner;
        color = 'gray';
      }
    } else if (allCandidatesDetailsError) {
      message = 'Failed to load additional fields.';
      icon = faExclamationTriangle;
      color = 'red';
    }

    return (
      <div style={{ fontSize: 12, fontWeight: 'bold', color: color }}>
        <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} spin={isGettingAllDetailedCandidates} />
        {message}
      </div>
    );
  };

  return (
    <div style={{ height: 'calc(100% - 81px)' }}>
      <div className="screenSizeWrapper" style={{ paddingBottom: 10 }}>
        <Col sm={12}>
          <Row>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div
                className="screenSize"
                style={{ display: 'flex', alignItems: 'row', justifyContent: 'space-between', width: '100%' }}
              ></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: '100%' }}>
              <div
                className="screenSize"
                style={{ paddingTop: 2, display: 'flex', alignItems: 'row', flexWrap: 'wrap', justifyContent: 'right' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: 5,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'row' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Button size="sm" color="info" style={{ marginLeft: 10 }} outline>
                        <div style={style.spacedBetweenRow}>
                          <div style={{ ...style.buttonLabel, marginRight: 10 }}>Selected</div>
                          <Badge color="secondary">
                            {selectedIndexes.length}/{filteredCandidates.length}
                          </Badge>
                        </div>
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Button size="sm" style={{ marginLeft: 10, width: 150 }} id={'select_by'}>
                        <div style={style.spacedBetweenRow}>
                          <div>
                            <FontAwesomeIcon icon={faUsers} />
                          </div>
                          <div style={style.buttonLabel}>Select by Field</div>
                        </div>
                      </Button>
                      {renderSelectPopover()}
                    </div>
                  </div>
                </div>

                {AllowPrescreen ? (
                  <Button
                    size="sm"
                    color="success"
                    style={style.actionButton}
                    disabled={selectedIndexes.length == 0}
                    id="pre_screen_button"
                  >
                    <div id="add_remove_btn" style={style.spacedBetweenRow}>
                      <div>
                        <FontAwesomeIcon icon={faClipboard} />
                      </div>
                      <div style={style.buttonLabel}>Assign/Remove Screeners</div>
                    </div>

                    {selectedIndexes.length == 0 ? (
                      <UncontrolledTooltip
                        modifiers={{
                          preventOverflow: { boundariesElement: 'viewport' },
                          flip: {
                            behavior: ['top', 'right', 'bottom', 'left'],
                            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                          },
                        }}
                        target="add_remove_btn"
                      >
                        You must select one or more candidates.
                      </UncontrolledTooltip>
                    ) : null}
                  </Button>
                ) : null}

                {!showDeletedOnly && filter != 5 && filter != 8 ? (
                  <>
                    <Button
                      size="sm"
                      color="primary"
                      style={{ ...style.actionButton, width: 'auto' }}
                      disabled={selectedIndexes.length == 0}
                      id="mark_button"
                    >
                      <div style={style.simpleRow}>
                        <div>
                          <FontAwesomeIcon icon={faMarker} />
                        </div>
                        <div style={style.buttonLabel}>Mark Candidate{selectedIndexes.length > 1 ? 's' : null}</div>
                      </div>
                    </Button>
                    {renderMarkPopover()}
                  </>
                ) : (
                  <Button
                    size="sm"
                    color="primary"
                    style={{ ...style.actionButton, width: 'auto' }}
                    disabled={selectedIndexes.length == 0}
                    onClick={() => {
                      restoreSelected(filter, () => {
                        // console.log('RESTORED!');
                        setIsUpdating(true);
                        getAllCandidatesData(() => {
                          setIsUpdating(false);
                        });
                      });
                    }}
                  >
                    <div style={style.simpleRow}>
                      <div>
                        <FontAwesomeIcon icon={faMarker} />
                      </div>
                      <div style={style.buttonLabel}>
                        Restore Candidate{selectedIndexes.length > 1 ? 's' : null} from{' '}
                        {showDeletedOnly ? 'Deactivated' : filterOptions[filter]}
                      </div>
                    </div>
                  </Button>
                )}

                <Button
                  size="sm"
                  color="primary"
                  style={{ ...style.actionButton, width: 'auto' }}
                  disabled={selectedIndexes.length == 0}
                  id="tags_button"
                >
                  <div style={style.simpleRow}>
                    <div>
                      <FontAwesomeIcon icon={faTags} />
                    </div>
                    <div style={style.buttonLabel}>Tag Candidate{selectedIndexes.length > 1 ? 's' : null}</div>
                  </div>
                </Button>
                {renderTagsPopover()}
                <Button
                  size="sm"
                  color={showDeletedOnly ? 'primary' : 'danger'}
                  style={{ ...style.actionButton, width: 'auto', minWidth: showDeletedOnly ? 190 : 240 }}
                  onClick={() => {
                    setShowDeletedOnly(!showDeletedOnly);
                  }}
                >
                  <div style={style.simpleRow}>
                    <div>
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                    <div style={{ ...style.buttonLabel, marginLeft: 0 }}>
                      {showDeletedOnly
                        ? 'View Active Candidates'
                        : `View ${deletedCandidates.length} Deactivated Candidate${
                            deletedCandidates.length > 1 ? 's' : ''
                          }`}
                    </div>
                  </div>
                </Button>
                {(showDeletedOnly && userDetails && userDetails.EnableSuperUser) || filter != 1 ? (
                  <Button
                    size="sm"
                    color="danger"
                    style={{ ...style.actionButton, width: 'auto' }}
                    disabled={selectedIndexes.length == 0}
                    onClick={() => {
                      const scheduledCandidates = selectedCandidates.filter((candidate) => {
                        return candidate.DateOfInterview != null;
                      });
                      let hasScheduledCandidates = scheduledCandidates.length > 0;

                      if (hasScheduledCandidates) {
                        setShowScheduledNotif(true);
                      } else {
                        if (showDeletedOnly) {
                          setShowConfirmDelete(true);
                        } else {
                          setShowConfirmRemove(true);
                        }
                      }
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <div>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                      <div style={style.buttonLabel}>
                        {showDeletedOnly
                          ? `Permanently Delete ${selectedCandidates.length > 1 ? 'Candidates' : 'Candidate'}`
                          : `Deactivate ${selectedCandidates.length > 1 ? 'Candidates' : 'Candidate'}`}
                      </div>
                    </div>
                  </Button>
                ) : null}

                <Button
                  size="sm"
                  color="primary"
                  style={{ ...style.actionButton, width: 'auto' }}
                  disabled={csvData.length == 0}
                  id="download_button"
                  onClick={() => {
                    document.getElementById(`download_actual_${sortBy}_${sortStatus[sortBy]}`).click();
                  }}
                >
                  <div style={style.simpleRow}>
                    <div>
                      <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div style={style.buttonLabel}>Download CSV</div>
                  </div>
                </Button>

                <div style={{ visibility: 'hidden' }}>
                  <CSVLink
                    data={csvData}
                    target="_blank"
                    id={`download_actual_${sortBy}_${sortStatus[sortBy]}`}
                    key={`download_actual_${sortBy}_${sortStatus[sortBy]}`}
                    filename={`${dContext.department.DepartmentName}_Export_Candidates_${moment().format(
                      'MMM DD, YYYY hh:mm A',
                    )}`}
                  />
                </div>

                <Button
                  size="sm"
                  disabled={selectedIndexes.length == 0}
                  style={{ ...style.actionButton, width: 'auto' }}
                  id="date_scheduler"
                  color="primary"
                >
                  <div style={style.simpleRow}>
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 10 }} />
                    <div style={style.buttonLabel}>
                      {' '}
                      Register Candidate{selectedCandidates.length > 1 ? 's' : ''}
                      {renderDatePickerPopover()}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </Row>
        </Col>
      </div>
      {renderIndicatorLabel()}
      {isGettingAllDetailedCandidates && Object.values(allCandidatesDetails) <= 0 ? (
        <div
          style={{
            width: '100%',
            height: 100,
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20vh',
          }}
        >
          <Loading
            options={{
              labelText: 'Loading. . .',
            }}
          />
        </div>
      ) : (
        <div style={{ width: '100%', height: 'calc(100vh - 400px)', overflowX: 'scroll' }}>
          <BootstrapTable
            data={clone(filteredCandidates)}
            columns={makeColumns(clone(dataTypes.enabled))}
            keyField="Name"
            options={options}
            noDataIndication={<div style={{ justifyContent: 'center', display: 'flex' }}>No Data!</div>}
            headerWrapperClasses="candidateListTableHeader"
          />
        </div>
      )}

      {renderDeleteConfirmation()}
      {renderRemoveConfirmation()}
      {renderScheduledNotif()}
      {AllowPrescreen ? renderPreScreenList() : null}
    </div>
  );
};

export default CandidateListWithFilters;

import React, { useContext, useEffect, useState } from 'react';
import AdminImportCandidates from '../AdminImportCandidates/AdminImportCandiates';
import DragAndDropFiles from 'Body/DragAndDropFiles/DragAndDropFiles';
import { fetchCandidates } from 'Services/CandidatesAPI';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';
import { faFileCsv, faFileImport, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import style from './CandidateImports.style';
import { PopupWithCloseButton } from 'Common.jsx';
import { DepartmentContext } from 'DepartmentWrapper';
import DocumentBulkImporter from '../DocumentBulkImporter/DocumentBulkImporter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const importTypes = {
  SFMatch: 'SFMatch',
  Eras: 'Eras',
};

const card = {
  alignItems: 'center',
  border: '3px solid',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  height: '150px',
  justifyContent: 'center',
  padding: '15px',
};

const CandidateImports = (props) => {
  const {
    documentTypes = [],
    updateParentCandidateList,
    allCandidatesDetails,
    getAllCandidatesData,
    getCandidatesParent,
    changes,
    setChanges,
  } = props;

  const [candidates, setCandidates] = useState([]);
  const [importErasOpen, setImportErasOpen] = useState(false);
  const [bulkDocumentImportOpen, setBulkDocumentImportOpen] = useState(false);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(true);
  const [tabToShow, setTabToShow] = useState(1);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);
  const { department, season } = dContext;
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;

  useEffect(() => {
    getCandidates();
  }, []);

  useEffect(() => {}, [allCandidatesDetails]);

  useEffect(() => {
    if (pathname) {
      if (pathname.indexOf('/candidates_import') >= 0) {
        if (tabToShow != 1) {
          setTabToShow(1);
        }
      } else if (pathname.indexOf('/bulk_document_import' >= 0)) {
        if (tabToShow != 2) {
          setTabToShow(2);
        }
      }
    }
  }, [pathname]);

  const getCandidates = () => {
    const { pk_Season } = season;
    const { pk_Department } = department;
    if (pk_Season == null || pk_Department == null) {
      return;
    }
    setIsLoadingCandidates(true);
    getTokenSilently()
      .then((token) => {
        fetchCandidates(token, {
          pk_Department: department.pk_Department,
          pk_Season: season.pk_Season,
          callback: (candidates) => {
            setCandidates(candidates);
            getCandidatesParent();
            setIsLoadingCandidates(false);
          },
        });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderImportCandidateData = () => {
    return (
      <AdminImportCandidates
        importType={importTypes.Eras}
        importTypes={importTypes}
        setImportErasOpen={setImportErasOpen}
        getCandidates={getCandidates}
        existingCandidatesReference={allCandidatesDetails}
        updateParentCandidateList={updateParentCandidateList}
        getAllCandidatesData={getAllCandidatesData}
        changes={changes}
        setChanges={setChanges}
      />
    );
  };

  const renderBulkImport = () => {
    return (
      <DocumentBulkImporter
        changes={changes}
        setChanges={setChanges}
        getAllCandidatesData={getAllCandidatesData}
        allCandidatesDetails={allCandidatesDetails}
      />
    );
  };

  const renderErasImportPopup = () => {
    return (
      <PopupWithCloseButton
        closeOnDocumentClick={true}
        open={importErasOpen}
        closeFunction={() => setImportErasOpen(false)}
      >
        <div>
          <AdminImportCandidates
            importType={importTypes.Eras}
            importTypes={importTypes}
            setImportErasOpen={setImportErasOpen}
            getCandidates={getCandidates}
          />
        </div>
      </PopupWithCloseButton>
    );
  };

  const renderBulkImportPopup = () => {
    return (
      <PopupWithCloseButton
        closeOnDocumentClick={false}
        open={bulkDocumentImportOpen}
        closeFunction={() => setBulkDocumentImportOpen(false)}
      >
        <DragAndDropFiles documentTypes={documentTypes} />
      </PopupWithCloseButton>
    );
  };

  const renderContent = () => {
    switch (tabToShow) {
      case 1:
        return renderImportCandidateData();
      case 2:
        return renderBulkImport();
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        height: 'calc(100vh - 347px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <div style={{ ...style.simpleRow, justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
        <div
          style={{
            ...style.simpleColumn,
          }}
        >
          <div styke={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 220, justifyContent: 'flex-start', alignItems: 'center' }}>
              <div
                style={{
                  ...style.simpleRow,
                  backgroundColor: tabToShow == 1 ? '#D9EAF1' : 'white',
                  marginBottom: 10,
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                }}
              >
                <div
                  style={{
                    ...style.simpleColumn,
                    width: 70,
                    fontWeight: 'bold',
                    fontSize: 16,
                    padding: 5,
                  }}
                >
                  Step 1
                </div>
                <div
                  style={{
                    ...style.simpleColumn,
                    width: 170,
                    backgroundColor: tabToShow == 1 ? '#D9EAF1' : 'white',
                    padding: 5,
                  }}
                >
                  <div
                    style={{ ...card, borderColor: 'black' }}
                    className="hover_blue"
                    onClick={(e) => {
                      e.preventDefault();
                      const newPath = pathname.substring(0, pathname.lastIndexOf('/')) + '/candidates_import';
                      history.push(newPath);
                      // setTabToShow(1);
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} size="5x" />
                    <div style={{ fontWeight: '700' }}>Candidate Data</div>
                    <div style={{ fontWeight: '700' }}>Import</div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...style.simpleRow,
                  backgroundColor: tabToShow == 2 ? '#D9EAF1' : 'white',
                  marginBottom: 10,
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                }}
              >
                <div
                  style={{
                    ...style.simpleColumn,
                    width: 70,
                    backgroundColor: tabToShow == 2 ? '#D9EAF1' : 'white',
                    fontWeight: 'bold',
                    fontSize: 16,
                    padding: 5,
                  }}
                >
                  Step 2
                </div>
                <div
                  style={{
                    ...style.simpleColumn,
                    width: 160,
                    backgroundColor: tabToShow == 2 ? '#D9EAF1' : 'white',
                    padding: 5,
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 15,
                  }}
                >
                  <div
                    style={{ ...card, borderColor: 'black' }}
                    className="hover_blue"
                    onClick={() => {
                      const newPath = pathname.substring(0, pathname.lastIndexOf('/')) + '/bulk_document_import';
                      history.push(newPath);
                      // setTabToShow(2);
                    }}
                  >
                    <FontAwesomeIcon icon={faFileImport} size="5x" />
                    <div style={{ fontWeight: '700' }}>Bulk Document</div>
                    <div style={{ fontWeight: '700' }}>Import</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            ...style.simpleColumn,
            width: 'calc(100% - 320px)',
            backgroundColor: '#D9EAF1',
            height: '100%',
            // overflow: 'auto',
          }}
        >
          {renderContent()}
        </div>
      </div>

      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            backgroundColor: '#F0F0F0',
            border: '1px solid rgb(51, 51, 51)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '70vw',
            height: '150px',
            borderRadius: '15px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h5>Need help?</h5>
            <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
              Note: Candidates must be uploaded before Bulk Document Uploader can be used.
            </div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open('https://www.rezrate.com/support/candidate-data/', '_blank');
              }}
              style={{ marginBottom: 10, backgroundColor: 'rgb(51, 51, 51)' }}
            >
              <div style={style.spacedBetweenRow}>
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </div>
                <div style={style.buttonLabel}>Support Documentation</div>
              </div>
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CandidateImports;
